import React, { useMemo, useState } from 'react';

import { Heading, VStack, HStack } from '@chakra-ui/react';

import { StudentExceptionList, Typeahead } from '../components';
import { useUrlParams } from '../hooks';
import { useStudentsArray } from '../hooks/apiHelpers';
import { selectStudentsWithExceptions } from '../store/coursesSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { addTemporaryStudentException, selectTemporaryStudentExceptions } from '../store/uiSlice';

const StudentExceptions: React.FC = () => {
	const dispatch = useAppDispatch();
	const { courseId } = useUrlParams();
	const studentsArray = useStudentsArray();
	const [studentQuery, setStudentQuery] = useState('');
	const temporaryStudentExceptions = useAppSelector(selectTemporaryStudentExceptions);

	const studentsWithExceptions = useAppSelector((state) =>
		selectStudentsWithExceptions(state, courseId)
	);

	const typeaheadItems = useMemo(
		() =>
			studentsArray
				.filter((s) => !studentsWithExceptions.has(s.studentId))
				.filter((s) => !temporaryStudentExceptions.find((tse) => tse === s.studentId))
				.map((s) => s.fullName),
		[studentsArray, studentsWithExceptions, temporaryStudentExceptions]
	);

	const addTemporaryException = () => {
		const student = studentsArray.find((s) => s.fullName === studentQuery);

		/**
		 * If we don't find a student, we can't add a temporary exception
		 */
		if (!student) return;

		/**
		 * Safety checks, the UI may be blocking these things from happening at this point
		 */
		const alreadyHasException = studentsWithExceptions.has(student.studentId);
		const alreadyHasTemporaryException = temporaryStudentExceptions.find(
			(tse) => tse === student.studentId
		);

		if (alreadyHasException || alreadyHasTemporaryException) return;

		dispatch(addTemporaryStudentException(student.studentId));
	};

	return (
		<VStack justify="flex-start" align="flex-start" spacing={[6, 8]} mt="4">
			<HStack w="100%" justify="space-between">
				<Heading>Your student exceptions</Heading>
			</HStack>

			<Typeahead
				name="add-student"
				label="Add a student"
				items={typeaheadItems}
				value={studentQuery}
				setValue={setStudentQuery}
				formControlStyles={{
					width: { base: '100%', md: '40%' }
				}}
				onAddClick={addTemporaryException}
			/>

			<StudentExceptionList />
		</VStack>
	);
};

export default StudentExceptions;
