import { useParams } from 'react-router-dom';

interface UrlParams {
	courseId: number;
}

/**
 * Wrap `react-router-dom`'s `useParams` so we don't need to supply types every time. Additionally,
 * we can use this method to typecast params, leaving less work to the consumer.
 *
 * Also make sure it doesn't return undefined values. If `courseId` is undefined we can catch this
 * higher up the tree instead of having to deal with the possibility every time we use this hook.
 *
 * @returns object containing our url params
 */
export function useUrlParams(): UrlParams {
	const { courseId } = useParams<keyof UrlParams>() as { [K in keyof UrlParams]: string };
	return { courseId: Number(courseId) };
}
