import { useEffect, useMemo, useRef } from 'react';

import {
	Box,
	Drawer as ChDrawer,
	DrawerContent,
	DrawerBody,
	Text,
	Stack,
	Spinner,
	DrawerCloseButton,
	VisuallyHidden,
	chakra,
	ChakraProps
} from '@chakra-ui/react';

import { useGetHydrateQuery, useLazyGetChangesIndexQuery } from '../../api';
import { useShortcutToFocus, SHORTCUTS, useUrlParams } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
	selectChangeRequestDeepLink,
	selectSelectedChangeRequest,
	setSelectedChangeRequest
} from '../../store/uiSlice';
import { formatDate, getOS } from '../../utils';
import HistoryItem from './HistoryItem';
import StatusItem from './StatusItem';
import { drawerItemStyles } from './styles';

import type { DrawerProps } from '@chakra-ui/react';

type Props = {
	drawerProps: DrawerProps;
};

const Drawer: React.FC<Props> = (props) => {
	const { drawerProps } = props;
	const { courseId } = useUrlParams();
	const dispatch = useAppDispatch();
	const selectedChangeRequest = useAppSelector(selectSelectedChangeRequest);
	const changeRequestDeepLink = useAppSelector(selectChangeRequestDeepLink);
	const { data: hydrateData } = useGetHydrateQuery(courseId);
	const timeZone = hydrateData?.course.timeZone;
	const initialCalendar = hydrateData?.initialCalendar;
	const [getChangesIndexTrigger, getChangesIndexResult] = useLazyGetChangesIndexQuery();
	const selectedHistoryItemRef = useRef<HTMLButtonElement>(null);

	/**
	 * GET changes from the API every time we open the drawer
	 */
	useEffect(() => {
		if (drawerProps.isOpen) {
			getChangesIndexTrigger(courseId).then(() => {
				selectedHistoryItemRef.current?.focus();
			});
		}
	}, [courseId, drawerProps.isOpen, getChangesIndexTrigger]);

	const requestsInProgress = useMemo(
		() =>
			getChangesIndexResult.data?.requests
				? getChangesIndexResult.data?.requests.filter((r) => r.status !== 'completed')
				: [],
		[getChangesIndexResult.data?.requests]
	);

	const completedRequests = useMemo(
		() =>
			getChangesIndexResult.data?.requests
				? getChangesIndexResult.data?.requests.filter((r) => r.status === 'completed')
				: [],
		[getChangesIndexResult.data?.requests]
	);

	/**
	 * Get the first focusable element in the drawer for `aria-describedby`
	 */
	const firstRequestInDrawer = useMemo(() => {
		return requestsInProgress.length > 0
			? requestsInProgress[0].id
			: completedRequests.length > 0
			? completedRequests[0].id
			: 0;
	}, [completedRequests, requestsInProgress]);

	/**
	 * When the change request history changes the memos that calculate `firstRequestInDrawer`
	 * will update.  When this update occurs set the selected change request to the most recent
	 * request.
	 *
	 * We don't want this to re-execute when `changeRequestDeepLink` changes, only when
	 * `firstRequestInDrawer` changes.  This prevents the selected change request being changed
	 * when the user entered the app with a deep link.
	 */
	useEffect(() => {
		if (!changeRequestDeepLink) {
			dispatch(setSelectedChangeRequest({ changeRequestId: firstRequestInDrawer }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, firstRequestInDrawer]);

	useShortcutToFocus({
		keys: {
			Windows: SHORTCUTS.WINDOWS.drawer,
			Mac: SHORTCUTS.MAC.drawer,
			Other: SHORTCUTS.WINDOWS.drawer
		},
		element: selectedHistoryItemRef
	});

	const drawerContent = () => (
		<DrawerContent>
			<DrawerCloseButton aria-label="Close drawer" />
			<DrawerBody p="0">
				{requestsInProgress.length > 0 && (
					<Box px="6" py="4">
						<Text as="h2" fontSize="lg" fontWeight="medium">
							Pending updates
						</Text>
						<Text as="span" fontSize="sm" fontStyle="italic" fontWeight="normal">
							Updates may take up to 30 minutes to complete.
						</Text>
					</Box>
				)}
				{requestsInProgress.map((r) => (
					<StatusItem
						key={`change-request-status-${r.id}`}
						buttonRef={selectedChangeRequest === r.id ? selectedHistoryItemRef : null}
						request={r}
						aria-pressed={selectedChangeRequest === r.id ? 'true' : 'false'}
						aria-describedby={firstRequestInDrawer === r.id ? 'keyboard-controls-desc' : undefined}
					/>
				))}
				<Text
					as="h2"
					fontSize="lg"
					fontWeight="medium"
					px="6"
					pt={requestsInProgress.length ? '8' : '3'}
					pb="2">
					Due date management history
				</Text>
				<Stack as="ul" spacing="0" listStyleType="none">
					{completedRequests.map((r) => {
						return (
							<HistoryItem
								key={`change-request-${r.id}`}
								buttonRef={selectedChangeRequest === r.id ? selectedHistoryItemRef : null}
								request={r}
								aria-pressed={selectedChangeRequest === r.id ? 'true' : 'false'}
								aria-describedby={
									firstRequestInDrawer === r.id ? 'keyboard-controls-desc' : undefined
								}
							/>
						);
					})}
					{initialCalendar && (
						<Box className="history-item" as="li">
							<chakra.button
								sx={initialCourseHistoryItemStyles}
								ref={selectedChangeRequest === 0 ? selectedHistoryItemRef : null}
								aria-pressed={selectedChangeRequest === 0 ? 'true' : 'false'}
								aria-describedby={firstRequestInDrawer === 0 ? 'keyboard-controls-desc' : undefined}
								as="button"
								onClick={() => dispatch(setSelectedChangeRequest({ changeRequestId: 0 }))}>
								<Text
									as="div"
									fontSize="sm"
									fontWeight={selectedChangeRequest === 0 ? 'bold' : 'normal'}>
									Course created {formatDate(initialCalendar.startedDate, timeZone)}
								</Text>
							</chakra.button>
						</Box>
					)}
				</Stack>

				<VisuallyHidden
					id="keyboard-controls-desc"
					display={selectedHistoryItemRef.current ? 'none' : 'initial'}>
					Use {getOS() === 'Mac' ? SHORTCUTS.MAC.table : SHORTCUTS.WINDOWS.table} to navigate to the
					due dates table and {getOS() === 'Mac' ? SHORTCUTS.MAC.drawer : SHORTCUTS.WINDOWS.drawer}{' '}
					to navigate the list of change requests in the drawer.
				</VisuallyHidden>
			</DrawerBody>
		</DrawerContent>
	);

	return (
		<ChDrawer
			{...drawerProps}
			trapFocus={false}
			closeOnOverlayClick={false}
			blockScrollOnMount={false}
			useInert={false}
			finalFocusRef={undefined}
			autoFocus={false}
			returnFocusOnClose={false}>
			{getChangesIndexResult.isFetching ? (
				<DrawerContent justifyContent="center">
					<Spinner alignSelf="center" size="xl" />
				</DrawerContent>
			) : (
				drawerContent()
			)}
		</ChDrawer>
	);
};

export default Drawer;

const initialCourseHistoryItemStyles: ChakraProps['sx'] = {
	...drawerItemStyles,
	borderBottomColor: 'border.50'
};
