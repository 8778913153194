import { ComponentStyleConfig } from '@chakra-ui/react';

export const Checkbox: ComponentStyleConfig = {
	baseStyle: {
		container: {
			borderColor: 'brandBlue.500'
		}
	},
	defaultProps: {
		colorScheme: 'brandBlue'
	}
};
