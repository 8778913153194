import React from 'react';
import { FaEllipsisH } from 'react-icons/fa';

import { Menu, MenuButton, IconButton, Icon, MenuList, MenuItem } from '@chakra-ui/react';

import { useUrlParams } from '../../hooks';
import {
	addDueDate,
	addPenaltyPeriod,
	removeDueDate,
	removePenaltyPeriod
} from '../../store/actions';
import { removeStudentExceptions } from '../../store/coursesSlice';
import { useAppDispatch } from '../../store/hooks';
import { CourseRow, StudentExceptionRow } from '../../store/rowSelectors';
import { addTemporaryStudentException } from '../../store/uiSlice';
import { FamilyId } from '../../types';

interface Props {
	chapterFamilyId: FamilyId;
	studentId: number;
	courseRow: CourseRow | null;
	studentExceptionRow: StudentExceptionRow | null;
}

const StudentExceptionsActionsMenu: React.FC<Props> = ({
	chapterFamilyId,
	studentId,
	courseRow,
	studentExceptionRow
}) => {
	const { courseId } = useUrlParams();
	const dispatch = useAppDispatch();

	const studentExceptionExists = studentExceptionRow != null;
	const courseHasDueDate = courseRow?.dueDate != null;
	const studentHasDueDate = studentExceptionRow?.dueDate != null;
	const courseHasPenaltyPeriod =
		courseRow?.penaltyPeriodInDays != null && courseRow?.penaltyPeriodInDays > 0;
	const studentHasPenaltyPeriod =
		studentExceptionRow?.penaltyPeriodInDays != null &&
		studentExceptionRow?.penaltyPeriodInDays > 0;

	/**
	 * If there is no course-level due date, then this adds a due date just for the student.
	 */
	const handleAddDueDate =
		(studentExceptionExists && !studentHasDueDate) || (!studentExceptionExists && !courseHasDueDate)
			? () => {
					dispatch(
						addDueDate({
							chapterFamilyId,
							studentId,
							courseId
						})
					);
			  }
			: null;

	/**
	 * If there is no course-level penalty period, then this adds a penalty period just for the student.
	 * There must be a due date set on the student exception or the course level due date, to add a
	 * penalty period.
	 */
	const handleAddPenaltyPeriod =
		(studentExceptionExists && studentHasDueDate && !studentHasPenaltyPeriod) ||
		(!studentExceptionExists && courseHasDueDate && !courseHasPenaltyPeriod)
			? () => {
					dispatch(
						addPenaltyPeriod({
							chapterFamilyId,
							studentId,
							courseId
						})
					);
			  }
			: null;

	/**
	 * If there *is* a course-level due date, then this removes the due date just for the student.
	 * (That is, this student will not have a due date for the chapter, but everyone else w/o an exception will still have one.)
	 */
	const handleRemoveDueDate =
		(studentExceptionExists && studentHasDueDate) || (!studentExceptionExists && courseHasDueDate)
			? () => {
					dispatch(
						removeDueDate({
							chapterFamilyId,
							studentId,
							courseId
						})
					);
			  }
			: null;

	/**
	 * If there *is* a course-level penalty period, then this removes the penalty period just for the student.
	 * (That is, this student will not have a penalty period for the chapter, but everyone else w/o an exception will still have one.)
	 */
	const handleRemovePenaltyPeriod =
		(studentExceptionExists && studentHasPenaltyPeriod) ||
		(!studentExceptionExists && courseHasPenaltyPeriod)
			? () => {
					dispatch(
						removePenaltyPeriod({
							chapterFamilyId,
							studentId,
							courseId
						})
					);
			  }
			: null;

	/**
	 * Completely deletes this student exception. If the student exception for this row has not been saved yet
	 * (i.e. it's still in the draft), then selecting this option will completely remove it from `draft.changes`
	 * ("Save changes (N)" and "Discard N changes" will both decrement by 1, whereas deleting the student exception
	 * due date will *modify* an existing change in `draft.changes`).
	 */
	const handleDeleteStudentException = studentExceptionExists
		? () => {
				dispatch(
					removeStudentExceptions({
						courseId,
						studentId,
						chapterFamilyId
					})
				);

				/**
				 * Make sure a temporary exception exists so the UI doesn't abruptly disappear
				 */
				dispatch(addTemporaryStudentException(studentId));
		  }
		: null;

	const disabled = [
		handleAddDueDate,
		handleAddPenaltyPeriod,
		handleRemoveDueDate,
		handleRemovePenaltyPeriod,
		handleDeleteStudentException
	].every((fn) => fn == null);

	return (
		<Menu>
			<MenuButton
				disabled={disabled}
				aria-label="Open actions menu"
				as={IconButton}
				icon={<Icon as={FaEllipsisH} />}
				variant="text"
				sx={{
					_active: {
						bg: 'brandBlue.100'
					}
				}}
			/>
			<MenuList>
				{handleAddDueDate && <MenuItem onClick={handleAddDueDate}>Add due date</MenuItem>}
				{handleAddPenaltyPeriod && (
					<MenuItem onClick={handleAddPenaltyPeriod}>Add grace period</MenuItem>
				)}
				{handleRemovePenaltyPeriod && (
					<MenuItem onClick={handleRemovePenaltyPeriod}>Remove grace period</MenuItem>
				)}
				{handleRemoveDueDate && <MenuItem onClick={handleRemoveDueDate}>Remove due date</MenuItem>}
				{handleDeleteStudentException && (
					<MenuItem onClick={handleDeleteStudentException}>Revert to course setting</MenuItem>
				)}
			</MenuList>
		</Menu>
	);
};

export default StudentExceptionsActionsMenu;
