import { Select } from '@chakra-ui/react';

import { useUrlParams } from '../../hooks';
import { selectStudentSelectOptions } from '../../store/coursesSlice';
import { useAppSelector } from '../../store/hooks';
import { FamilyId } from '../../types';
import { inputStyle } from '../DueDatesTable/styles';

interface Props {
	value: number;
	chapterFamilyId: FamilyId;
	onChange: (studentId: number) => void;
	showDefault?: boolean;
}

const StudentSelect: React.FC<Props> = ({ value, chapterFamilyId, onChange, showDefault }) => {
	const { courseId } = useUrlParams();
	const studentOptions = useAppSelector((state) =>
		selectStudentSelectOptions(state, courseId, chapterFamilyId, value)
	);

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		onChange(Number(e.target.value));
	};

	return (
		<Select
			aria-label="Student select"
			sx={inputStyle}
			variant="unstyled"
			defaultValue={value}
			onChange={handleChange}>
			{showDefault && <option value={0}>Select a student</option>}
			{studentOptions.map((stu) => (
				<option key={`student-${stu.studentId}`} value={stu.studentId}>
					{stu.fullName}
				</option>
			))}
		</Select>
	);
};
export default StudentSelect;
