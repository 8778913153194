import { ComponentStyleConfig } from '@chakra-ui/react';
import { cssVar, calc } from '@chakra-ui/theme-tools';

const trackWidth = '36px';
const trackHeight = '16px';
const $diff = cssVar('switch-track-diff');
const diffValue = calc.subtract(trackWidth, trackHeight);

export const Switch: ComponentStyleConfig = {
	baseStyle: {},
	variants: {
		brand: {
			container: {
				[$diff.variable]: diffValue
			},
			track: {
				borderColor: 'black',
				borderWidth: 1,
				bg: 'gray.600',
				width: trackWidth,
				height: trackHeight,
				_checked: {
					bg: 'brandBlue.500'
				}
			},
			thumb: {
				width: trackHeight,
				height: trackHeight
			}
		}
	},
	defaultProps: {
		variant: 'brand'
	}
};
