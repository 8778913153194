import React, { useRef } from 'react';

import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody
} from '@chakra-ui/react';

interface Props {
	isOpen: boolean;
}

const SessionExpiredAlert: React.FC<Props> = React.memo((props) => {
	const { isOpen } = props;
	const headerRef = useRef<HTMLElement>(null);

	return (
		<AlertDialog
			size="lg"
			isCentered
			isOpen={isOpen}
			onClose={() => {}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			leastDestructiveRef={headerRef}>
			<AlertDialogOverlay backdropFilter="blur(6px)">
				<AlertDialogContent>
					<AlertDialogHeader fontWeight="bold" ref={headerRef} tabIndex={-1}>
						Your due date manager session has expired.
					</AlertDialogHeader>

					<AlertDialogBody fontSize="lg">
						To regain access, please close this browser window or tab, and then relaunch the due
						date manager from the Tools menu of your webtext.
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
});

SessionExpiredAlert.displayName = 'SessionExpiredAlert';

export default SessionExpiredAlert;
