import { ChakraProps } from '@chakra-ui/react';

export const drawerItemStyles: ChakraProps['sx'] = {
	w: '100%',
	px: 6,
	py: 4,
	bg: 'white',
	textAlign: 'start',
	borderTopColor: 'border.50',
	borderWidth: '1px',
	'&[aria-pressed="true"]': {
		bg: 'purple.100',
		borderColor: 'purple.700',
		borderWidth: '1px !important',
		borderStyle: 'inset'
	},
	'&[aria-pressed="false"]': {
		_hover: {
			bg: 'gray.100'
		}
	}
};
