import Rollbar from 'rollbar';

const rollbarInstance =
	import.meta.env.MODE !== 'test' && import.meta.env.MODE !== 'development'
		? new Rollbar({
				accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
				/**
				 * this option (and `captureUnhandledRejections`) doesn't actually seem to work,
				 * so we have to set up window.addEventListener("error | unhandledrejection") manually
				 */
				// captureUncaught: true,
				// captureUnhandledRejections: true,
				payload: {
					environment: import.meta.env.MODE,
					apiBase: import.meta.env.VITE_API_BASE
				}
		  })
		: null;

type LoggingFunction = (...args: Rollbar.LogArgument[]) => Rollbar.LogResult | undefined;

export const logError: LoggingFunction = (...args) => {
	console.error(...args);
	return rollbarInstance?.error(...args);
};

export const logWarn: LoggingFunction = (...args) => {
	console.warn(...args);
	return rollbarInstance?.warn(...args);
};

export const logInfo: LoggingFunction = (...args) => {
	console.info(...args);
	return rollbarInstance?.info(...args);
};

export const logDebug: LoggingFunction = (...args) => {
	console.debug(...args);
	return rollbarInstance?.debug(...args);
};
