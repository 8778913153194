import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import { ddmApi } from '../api';
import coursesReducer from './coursesSlice';
import uiReducer from './uiSlice';

const persistedUiReducer = persistReducer(
	{
		key: 'ui',
		version: 1,
		storage: sessionStorage,
		whitelist: ['accessToken', 'userInfo']
	},
	uiReducer
);

const combinedReducer = combineReducers({
	courses: coursesReducer,
	ui: persistedUiReducer,
	[ddmApi.reducerPath]: ddmApi.reducer
});

export const store = configureStore({
	reducer: combinedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		}).concat(ddmApi.middleware)
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
export type AppThunkApi = {
	dispatch: AppDispatch;
	state: RootState;
};
