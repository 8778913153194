import { ComponentStyleConfig } from '@chakra-ui/react';

export const Modal: ComponentStyleConfig = {
	baseStyle: {
		dialog: {
			borderRadius: 0,
			mt: '-6rem',
			px: 4,
			py: 6
		},
		header: {
			fontSize: '24px',
			fontWeight: 'bold',
			pb: 2
		}
	},
	sizes: {
		xl: {
			dialog: {
				maxW: '40rem'
			}
		}
	},
	defaultProps: {
		size: 'xl'
	}
};
