import { useMemo } from 'react';

import { useGetHydrateQuery } from '../api';
import { useUrlParams } from './useUrlParams';

export function useStudentsArray() {
	const { courseId } = useUrlParams();
	const { data: hydrateDate } = useGetHydrateQuery(courseId);
	const students = hydrateDate?.students;

	return useMemo(() => {
		if (!students) return [];
		return Object.keys(students).map((studentId) => students[studentId]);
	}, [students]);
}
