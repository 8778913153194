import { useEffect, useRef, useState } from 'react';

import { chakra, PropsOf } from '@chakra-ui/react';

import { inputStyle } from '../DueDatesTable/styles';

export interface NumberInputProps extends PropsOf<typeof chakra.input> {
	value: number;
	onChange: (value: number) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({ value, onChange, ...rest }) => {
	const [inputValue, setInputValue] = useState<string>(`${value}`);
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setInputValue(`${value}`);
	}, [value]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
		if (ref.current?.validity.valid) {
			onChange(Number(e.target.value));
		}
	};

	return (
		<chakra.input
			type="number"
			sx={inputStyle}
			value={inputValue}
			ref={ref}
			required
			onChange={handleChange}
			onFocus={(e) => {
				e.target.select();
			}}
			{...rest}
		/>
	);
};
export default NumberInput;
