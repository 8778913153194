import React from 'react';

import { Td, Th, Tr, chakra } from '@chakra-ui/react';
import { DateTime } from 'luxon';

import { selectCourseTimeZone } from '../../api/selectors';
import { useUrlParams } from '../../hooks';
import { createOrUpdate } from '../../store/actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { CourseRow, DueDatesTableRow, StudentExceptionsTableRow } from '../../store/rowSelectors';
import { selectStudentExceptionGracePeriodInputType } from '../../store/uiSlice';
import { dueDateDueTimeToISO, formatDate, getFormattedTimeFromISOWithTimeZone } from '../../utils';
import { DatePicker, PenaltyPercentageInput, PenaltyPeriodLengthInput, TimePicker } from '../Input';
import StudentExceptionsActionsMenu from './StudentExceptionsActionsMenu';
import useMinMaxDate from './useMinMaxDate';

interface Props {
	studentId: number;
	row: StudentExceptionsTableRow;
}

const StudentExceptionRow: React.FC<Props> = ({ studentId, row }) => {
	const { courseRow, studentExceptionRow, chapter } = row;
	const { dueDate, dueTime, penaltyPercentage, penaltyPeriodInDays } =
		studentExceptionRow ?? courseRow ?? {};
	const { courseId } = useUrlParams();
	const dispatch = useAppDispatch();
	const { minDate, maxDate } = useMinMaxDate(courseId);
	const courseTimeZone = useAppSelector((state) => selectCourseTimeZone(state, courseId));
	const courseDueISO =
		courseRow?.dueDate && courseRow?.dueTime
			? dueDateDueTimeToISO(courseRow.dueDate, courseRow.dueTime, courseTimeZone)
			: null;

	const handleChange = (update: Partial<DueDatesTableRow>) => {
		dispatch(
			createOrUpdate({
				courseId,
				chapterFamilyId: chapter.familyId,
				studentId,
				update
			})
		);
	};

	const courseDueDateDisplayValue = courseDueISO
		? formatDate(courseDueISO, courseTimeZone)
		: 'not set';
	const courseDueTimeDisplayValue = courseDueISO
		? getFormattedTimeFromISOWithTimeZone(courseDueISO, courseTimeZone)
		: 'not set';

	const hasCoursePenaltyPeriod =
		courseRow?.penaltyPeriodInDays != null && courseRow.penaltyPeriodInDays > 0;
	const hasStudentPenaltyPeriod =
		studentExceptionRow?.penaltyPeriodInDays != null && studentExceptionRow.penaltyPeriodInDays > 0;
	/**
	 * Whether to show the course-level penalty percentage and penalty period values (or "not set")
	 * beneath the student exception penalty period inputs.
	 *
	 * We should show them when a student exception exists for this chapter, and
	 * - either the course-level penalty period or the student exception penalty period is set
	 */
	const shouldShowCoursePenaltyPeriodValues =
		studentExceptionRow != null && (hasCoursePenaltyPeriod || hasStudentPenaltyPeriod);

	/**
	 * Whether to show the course-level due date and due time beneath the student exception due date / due time inputs.
	 *
	 * We should show them when a student exception exists for this chapter, and
	 * - either the course-level due date/time is different from the student exception due date/time,
	 * - or we are already displaying the course-level penalty period values
	 */
	const shouldShowCourseDueDateValues =
		// base condition
		(studentExceptionRow != null &&
			(courseRow?.dueDate !== studentExceptionRow?.dueDate ||
				courseRow.dueTime !== studentExceptionRow.dueTime)) ||
		shouldShowCoursePenaltyPeriodValues;

	return (
		<Tr className={Number(chapter.number) % 2 === 0 ? 'even' : 'odd'}>
			<Th scope="row">{`${chapter.number} ${chapter.name}`}</Th>
			<Td>
				<CellContent>
					{dueDate != null && (
						<DatePicker
							aria-label="Due date"
							date={dueDate}
							onChange={(date) =>
								handleChange({
									dueDate: date
								})
							}
							min={minDate}
							max={maxDate}
						/>
					)}
					{shouldShowCourseDueDateValues && (
						<CourseValue>Course: {courseDueDateDisplayValue}</CourseValue>
					)}
				</CellContent>
			</Td>
			<Td>
				<CellContent>
					{dueDate != null && dueTime != null && (
						<TimePicker
							date={dueDate}
							time={dueTime}
							onChange={(time) =>
								handleChange({
									dueTime: time
								})
							}
						/>
					)}
					{shouldShowCourseDueDateValues && <CourseValue>{courseDueTimeDisplayValue}</CourseValue>}
				</CellContent>
			</Td>
			<Td>
				<CellContent>
					{penaltyPercentage != null && penaltyPeriodInDays != null && penaltyPeriodInDays > 0 && (
						<PenaltyPercentageInput
							value={penaltyPercentage}
							onChange={(value) =>
								handleChange({
									penaltyPercentage: value
								})
							}
						/>
					)}
					{shouldShowCoursePenaltyPeriodValues && (
						<CourseValue>
							{courseRow?.penaltyPeriodInDays != null &&
							courseRow.penaltyPeriodInDays > 0 &&
							courseRow.penaltyPercentage != null
								? `${courseRow.penaltyPercentage} %`
								: 'not set'}
						</CourseValue>
					)}
				</CellContent>
			</Td>
			<Td>
				<CellContent>
					{penaltyPeriodInDays != null && penaltyPeriodInDays > 0 && (
						<PenaltyPeriodLengthInput
							dueDate={dueDate!}
							tableType="student-exceptions"
							value={penaltyPeriodInDays}
							onChange={(value) =>
								handleChange({
									penaltyPeriodInDays: value
								})
							}
						/>
					)}
					{shouldShowCoursePenaltyPeriodValues && (
						<GracePeriodDurationCourseValue courseRow={courseRow} courseTimeZone={courseTimeZone} />
					)}
				</CellContent>
			</Td>
			<Td>
				<StudentExceptionsActionsMenu
					chapterFamilyId={chapter.familyId}
					studentId={studentId}
					courseRow={courseRow}
					studentExceptionRow={studentExceptionRow}
				/>
			</Td>
		</Tr>
	);
};
export default StudentExceptionRow;

const CellContent = chakra('div', {
	baseStyle: {
		display: 'grid',
		py: 2,
		rowGap: 2,
		justifyItems: 'end'
	}
});

const CourseValue = chakra('span', {
	baseStyle: {
		fontSize: 12,
		fontStyle: 'italic'
	}
});

const GracePeriodDurationCourseValue: React.FC<{
	courseRow: CourseRow | null;
	courseTimeZone: string;
}> = ({ courseRow, courseTimeZone }) => {
	const studentExceptionGracePeriodInputType = useAppSelector(
		selectStudentExceptionGracePeriodInputType
	);

	const isCourseGracePeriodSet =
		courseRow?.penaltyPeriodInDays != null && courseRow.penaltyPeriodInDays > 0;
	let courseDisplayValue = 'not set';
	if (isCourseGracePeriodSet) {
		const gracePeriodEndDate = DateTime.fromISO(courseRow.dueDate!)
			.plus({ days: courseRow.penaltyPeriodInDays })
			.toISODate();
		courseDisplayValue =
			studentExceptionGracePeriodInputType === 'end-date'
				? formatDate(gracePeriodEndDate, courseTimeZone)
				: `${courseRow.penaltyPeriodInDays} day${courseRow.penaltyPeriodInDays !== 1 ? 's' : ''}`;
	}

	return <CourseValue>{courseDisplayValue}</CourseValue>;
};
