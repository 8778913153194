import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useParams } from 'react-router-dom';

import {
	Container,
	Stack,
	Spinner,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useDisclosure,
	Box
} from '@chakra-ui/react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { useCreateChangesMutation, useLazyGetHydrateQuery } from '../api';
import { setupPushNotifications, teardownPushNotifications } from '../cable';
import {
	Button,
	ConflictAlert,
	Drawer,
	SaveConfirmationModal,
	DeleteEarlyStudentExceptionsModal,
	SessionExpiredAlert
} from '../components';
import ErrorMessage from '../components/ErrorMessage';
import { logError, logInfo } from '../rollbar';
import { discardChanges, resetDraftToHeadCalendar } from '../store/actions';
import { selectChanges, selectDraftHeadCalendarId } from '../store/coursesSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
	selectJwt,
	resetTemporaryStudentExceptions,
	selectDrawerOpen,
	setEditing,
	setDrawerOpen,
	setShowStudentExceptions,
	selectEarlyStudentExceptions,
	setEarlyStudentExceptions,
	setSelectedChangeRequest,
	selectSessionExpired
} from '../store/uiSlice';
import { dueDateToServerDueDate } from '../utils';
import { AllDueDates, StudentExceptions } from '../views';

const Course: React.FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { courseId } = useParams();
	const drawerOpen = useAppSelector(selectDrawerOpen);
	const earlyStudentExceptions = useAppSelector(selectEarlyStudentExceptions);
	const sessionExpired = useAppSelector(selectSessionExpired);

	/**
	 * The `changes` and `draftHeadCalendarId` selectors will not have valid data until
	 * `hydrateResult.isSuccess && hydrateResult.data`, but we have to call them anyway
	 * because we can't call hooks conditionally
	 */
	const changes = useAppSelector((state) => selectChanges(state, Number(courseId)));
	const draftHeadCalendarId = useAppSelector((state) =>
		selectDraftHeadCalendarId(state, Number(courseId))
	);

	const [activeTab, setActiveTab] = useState(0);
	const onOpenDrawer = useCallback(() => dispatch(setDrawerOpen(true)), [dispatch]);
	const viewHistoryButtonRef = useRef<HTMLButtonElement>(null);

	const toggleDrawer = () => dispatch(setDrawerOpen(!drawerOpen));
	const onCloseDrawer = () => {
		dispatch(setDrawerOpen(false));
		viewHistoryButtonRef.current?.focus();
	};

	const {
		isOpen: isConflictModalOpen,
		onOpen: onOpenConflictModal,
		onClose: onCloseConflictModal
	} = useDisclosure();

	const {
		isOpen: isSaveConfirmationOpen,
		onOpen: onOpenSaveConfirmation,
		onClose: onCloseSaveConfirmation
	} = useDisclosure();

	const [hydrateTrigger, hydrateResult] = useLazyGetHydrateQuery();
	const [createChangeRequest, createChangeRequestResult] = useCreateChangesMutation();
	const jwt = useAppSelector(selectJwt);

	/**
	 * `editing` will always be the inverse of the drawer being open.  So if it is open the user
	 * will not be editing.  If it is closed the user will be editing.
	 *
	 * @todo This will not be true if T-51853 is implemented, and should be revisited then.
	 */
	useEffect(() => {
		if (drawerOpen) {
			setActiveTab(0);
			dispatch(setShowStudentExceptions(true));
		}

		dispatch(setEditing(!drawerOpen));
	}, [dispatch, drawerOpen]);

	/**
	 * Redirect to `/error` if there is a problem with `courseId`;
	 * otherwise, start fetching from the hydrate_ddm endpoint
	 */
	useEffect(() => {
		if (!courseId) {
			navigate('/error');
			logError(new Error('No courseId was specified'));
		} else {
			document.title = `Manage Due Dates: ${courseId} | Soomo`;

			hydrateTrigger(Number(courseId)).then((result) => {
				if (result.error != null && 'status' in result.error && result.error.status === 401) {
					// session expiry. do nothing in this case; api.ts#queryWithAuthCheck will setSessionExpired(true)
					// which will show a session expired alert
				} else if (result.isError) {
					navigate('/error');
					logError(new Error('hydrate_ddm fetch failed'), result.error);
				} else {
					dispatch(
						resetDraftToHeadCalendar({
							courseId: Number(courseId)
						})
					);
					setupPushNotifications({ jwt, courseId, dispatch });
					return () => teardownPushNotifications();
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseId]);

	useEffect(() => {
		const { isSuccess, requestId, error, data } = createChangeRequestResult;

		/**
		 * Change request created successfully.  We need to update the drafts head calendar, clear
		 * the changes array. Then select the most recent change request.
		 */
		if (isSuccess) {
			dispatch(
				resetDraftToHeadCalendar({
					courseId: Number(courseId),
					requestId
				})
			);
			dispatch(setSelectedChangeRequest({ changeRequestId: data.id }));
			onOpenDrawer();
		} else if (error) {
			if ((error as FetchBaseQueryError).status === 409) {
				/**
				 * Version conflict
				 */
				logInfo('Server returned 409 Conflict');
				onOpenConflictModal();
			} else if ((error as FetchBaseQueryError).status === 400) {
				logError(new Error('The requested change could not be applied'));
			} else if (sessionExpired) {
				/**
				 * Session has expired, this is handled elsewhere
				 */
			} else {
				/**
				 * Unknown error at this point
				 */
				navigate('/error');
				logError(new Error('An error occurred when trying to create a change request'), error);
			}
		}
	}, [
		courseId,
		createChangeRequestResult,
		dispatch,
		navigate,
		onOpenConflictModal,
		onOpenDrawer,
		sessionExpired
	]);

	const onBeforeUnloadHandler = useCallback((e: BeforeUnloadEvent) => {
		e.preventDefault();
		// Chrome needs return value to be set, even if it's not shown;
		// see https://stackoverflow.com/questions/45088861/whats-the-point-of-beforeunload-returnvalue-if-the-message-does-not-get-set
		// eslint-disable-next-line no-return-assign
		return (e.returnValue = '');
	}, []);

	useEffect(() => {
		if (changes.length > 0 && !sessionExpired) {
			window.addEventListener('beforeunload', onBeforeUnloadHandler);
			return () => window.removeEventListener('beforeunload', onBeforeUnloadHandler);
		} else {
			window.removeEventListener('beforeunload', onBeforeUnloadHandler);
		}
	}, [changes.length, onBeforeUnloadHandler, sessionExpired]);

	const onSave = useCallback(() => {
		if (!draftHeadCalendarId) return;

		const translatedChanges = changes.map((c) => dueDateToServerDueDate(c));

		createChangeRequest({
			courseId: Number(courseId),
			requestBody: {
				changes: translatedChanges,
				current_calendar_version: draftHeadCalendarId
			}
		});
	}, [changes, courseId, createChangeRequest, draftHeadCalendarId]);

	/**
	 * Don't render the page and leave opportunity for errors if there is a problem with `courseId`
	 */
	if (!courseId) {
		return null;
	}

	return (
		<>
			<ErrorBoundary
				FallbackComponent={ErrorMessage}
				onError={(error, info) => {
					const e = new Error('Component rendering error');
					e.stack = info.componentStack;
					logError(e, {
						originalError: {
							message: error.message,
							stack: error.stack,
							name: error.name
						}
					});
				}}>
				<Container
					maxW="1280px"
					px={{ base: 4, md: 6, lg: 10 }}
					py={{ base: 4, md: 10 }}
					pos="relative">
					{hydrateResult.isSuccess && hydrateResult.data ? (
						<Tabs
							isLazy
							lazyBehavior="unmount"
							index={activeTab}
							onChange={(tabIndex) => {
								setActiveTab(tabIndex);
								/**
								 * Reset temporary student exceptions when we switch tabs
								 */
								dispatch(resetTemporaryStudentExceptions());
							}}>
							<Stack
								justify={{ base: 'center', md: 'space-between' }}
								direction={{ base: 'column', md: 'row' }}
								align="stretch"
								spacing={{ base: 4, lg: 0 }}>
								<TabList justifyContent="center">
									<Tab>All due dates</Tab>
									<Tab>Student exceptions</Tab>
								</TabList>
								<Stack direction={['column', 'row']} justify={{ base: 'initial', sm: 'center' }}>
									<Button
										alignSelf="stretch"
										disabled={
											!changes.length ||
											createChangeRequestResult.isLoading ||
											hydrateResult.isFetching
										}
										py="0"
										onClick={onOpenSaveConfirmation}
										isLoading={createChangeRequestResult.isLoading}>
										{`Save ${changes.length || ''} change${changes.length !== 1 ? 's' : ''}`}
									</Button>
									<Button
										disabled={
											!changes.length ||
											createChangeRequestResult.isLoading ||
											hydrateResult.isFetching
										}
										py="0"
										onClick={() => {
											dispatch(discardChanges(Number(courseId)));
										}}
										isLoading={hydrateResult.isFetching}>
										{`Discard ${changes.length || ''} change${changes.length !== 1 ? 's' : ''}`}
									</Button>
									<Button
										variant="outline"
										py="0"
										onClick={toggleDrawer}
										aria-pressed={drawerOpen ? 'true' : 'false'}
										ref={viewHistoryButtonRef}>
										View history
									</Button>
								</Stack>
							</Stack>
							<TabPanels>
								<TabPanel maxW="865px" px="0">
									<AllDueDates />
								</TabPanel>
								<TabPanel maxW="865px" px="0">
									<StudentExceptions />
								</TabPanel>
							</TabPanels>
						</Tabs>
					) : (
						<Box display="flex" alignItems="center" justifyContent="center" paddingTop="30vh">
							<Spinner size="xl" />
						</Box>
					)}
				</Container>
			</ErrorBoundary>

			<ErrorBoundary
				FallbackComponent={ErrorMessage}
				onError={(error, info) => {
					const e = new Error('Drawer rendering error');
					e.stack = info.componentStack;
					logError(e, {
						originalError: {
							message: error.message,
							stack: error.stack,
							name: error.name
						}
					});
				}}>
				<Drawer
					drawerProps={{
						isOpen: drawerOpen,
						onClose: onCloseDrawer,
						size: 'md',
						children: null
					}}
				/>
			</ErrorBoundary>

			<ConflictAlert
				isOpen={isConflictModalOpen}
				onOpen={onOpenConflictModal}
				onClose={onCloseConflictModal}
			/>

			<SaveConfirmationModal
				isOpen={isSaveConfirmationOpen}
				onClose={onCloseSaveConfirmation}
				onContinue={onSave}
			/>

			<DeleteEarlyStudentExceptionsModal
				isOpen={Boolean(earlyStudentExceptions?.length)}
				onClose={() => {
					dispatch(setEarlyStudentExceptions([]));
				}}
			/>

			<SessionExpiredAlert isOpen={sessionExpired} />
		</>
	);
};

export default Course;
