import React, { useEffect, useState } from 'react';

import { chakra, PropsOf } from '@chakra-ui/react';

import { ISO8601Date } from '../../types';
import { inputStyle } from '../DueDatesTable/styles';

interface Props extends PropsOf<typeof chakra.input> {
	date: ISO8601Date;
	onChange: (date: ISO8601Date) => void;
}

const DatePicker: React.FC<Props> = (props) => {
	const { date: initialDate, onChange, min, max, ...rest } = props;
	const [inputValue, setInputValue] = useState(initialDate);

	useEffect(() => {
		setInputValue(initialDate);
	}, [initialDate]);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		setInputValue(e.target.value);
		if (e.target.validity.valid) {
			onChange(e.target.value);
		}
	};

	return (
		<chakra.input
			type="date"
			required
			sx={{
				...inputStyle,
				w: 136,
				pr: 1,
				textAlign: 'end',
				'&::-webkit-calendar-picker-indicator': {
					ml: 0.5
				}
			}}
			value={inputValue}
			onChange={handleChange}
			min={min}
			max={max}
			{...rest}
		/>
	);
};

export default DatePicker;
