import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StudentExceptionDueDate } from '../types';

import type { RootState } from '.';
import type { StudentExceptionRow } from './rowSelectors';

export type UserInfo = {
	userId: string;
	intercomHash: string;
	fullstoryEnabled: boolean;
	fullstoryProperties: any;
	email: string;
	firstName: string;
	lastName: string;
};

export type GracePeriodInputType = 'number-of-days' | 'end-date';

interface UiState {
	accessToken: string;
	userInfo: UserInfo;
	openStudentException: number;
	showStudentExceptions: boolean;
	temporaryStudentExceptions: number[];
	editing: boolean;
	duplicatingStudentException?: StudentExceptionRow;
	selectedChangeRequest: number;
	changeRequestDeepLink: boolean;
	drawerOpen: boolean;
	earlyStudentExceptions?: StudentExceptionDueDate[];
	sessionExpired: boolean;
	courseGracePeriodInputType: GracePeriodInputType;
	studentExceptionGracePeriodInputType: GracePeriodInputType;
}

const initialState: UiState = {
	accessToken: '',
	userInfo: {
		userId: '',
		intercomHash: '',
		fullstoryEnabled: false,
		fullstoryProperties: {},
		email: '',
		firstName: '',
		lastName: ''
	},
	openStudentException: 0,
	showStudentExceptions: false,
	temporaryStudentExceptions: [],
	editing: true,
	selectedChangeRequest: -1,
	changeRequestDeepLink: false,
	drawerOpen: false,
	sessionExpired: false,
	courseGracePeriodInputType: 'number-of-days',
	studentExceptionGracePeriodInputType: 'end-date'
};

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setAccessToken: (state, action: PayloadAction<string>) => {
			state.accessToken = action.payload;
		},
		setUserInfo: (state, action: PayloadAction<UserInfo>) => {
			state.userInfo = action.payload;
		},
		setOpenStudentException: (state, action: PayloadAction<number>) => {
			state.openStudentException = action.payload;
		},
		setShowStudentExceptions: (state, action: PayloadAction<boolean>) => {
			state.showStudentExceptions = action.payload;
		},
		addTemporaryStudentException: (state, action: PayloadAction<number>) => {
			if (!state.temporaryStudentExceptions.find((tse) => tse === action.payload)) {
				state.temporaryStudentExceptions.push(action.payload);
				state.openStudentException = action.payload;
			}
		},
		removeTemporaryStudentException: (state, action: PayloadAction<number>) => {
			state.temporaryStudentExceptions = state.temporaryStudentExceptions.filter(
				(tse) => !(tse === action.payload)
			);
		},
		resetTemporaryStudentExceptions: (state) => {
			state.temporaryStudentExceptions = [];
		},
		setDuplicatingStudentException: (
			state,
			action: PayloadAction<StudentExceptionRow | undefined>
		) => {
			state.duplicatingStudentException = action.payload;
		},
		setSelectedChangeRequest: (
			state,
			action: PayloadAction<{ changeRequestId: number; deepLink?: boolean }>
		) => {
			state.selectedChangeRequest = action.payload.changeRequestId;
			state.changeRequestDeepLink = Boolean(action.payload.deepLink);
		},
		setEditing: (state, action: PayloadAction<boolean>) => {
			state.editing = action.payload;
		},
		setDrawerOpen: (state, action: PayloadAction<boolean>) => {
			state.drawerOpen = action.payload;
		},
		setEarlyStudentExceptions: (state, action: PayloadAction<StudentExceptionDueDate[]>) => {
			state.earlyStudentExceptions = action.payload;
		},
		setSessionExpired: (state, action: PayloadAction<boolean>) => {
			state.sessionExpired = action.payload;
		},
		setCourseGracePeriodInputType: (state, action: PayloadAction<GracePeriodInputType>) => {
			state.courseGracePeriodInputType = action.payload;
		},
		setStudentExceptionGracePeriodInputType: (
			state,
			action: PayloadAction<GracePeriodInputType>
		) => {
			state.studentExceptionGracePeriodInputType = action.payload;
		}
	}
});

export const {
	setAccessToken,
	setOpenStudentException,
	setShowStudentExceptions,
	addTemporaryStudentException,
	removeTemporaryStudentException,
	resetTemporaryStudentExceptions,
	setDuplicatingStudentException,
	setSelectedChangeRequest,
	setEditing,
	setDrawerOpen,
	setEarlyStudentExceptions,
	setUserInfo,
	setSessionExpired,
	setCourseGracePeriodInputType,
	setStudentExceptionGracePeriodInputType
} = uiSlice.actions;

const uiReducer = uiSlice.reducer;

export const selectUi = (state: RootState): UiState => state.ui;

export const selectOpenStudentException = createSelector(
	selectUi,
	(state) => state.openStudentException
);

export const selectShowStudentExceptions = createSelector(
	selectUi,
	(state) => state.showStudentExceptions
);

export const selectTemporaryStudentExceptions = createSelector(
	selectUi,
	(state) => state.temporaryStudentExceptions
);

export const selectEditing = createSelector(selectUi, (state) => state.editing);

export const selectDuplicatingStudentException = createSelector(
	selectUi,
	(state) => state.duplicatingStudentException
);

export const selectSelectedChangeRequest = createSelector(
	selectUi,
	(state) => state.selectedChangeRequest
);

export const selectChangeRequestDeepLink = createSelector(
	selectUi,
	(state) => state.changeRequestDeepLink
);

export const selectJwt = createSelector(selectUi, (state) => state.accessToken);

export const selectDrawerOpen = createSelector(selectUi, (state) => state.drawerOpen);

export const selectEarlyStudentExceptions = createSelector(
	selectUi,
	(state) => state.earlyStudentExceptions
);

export const selectUserInfo = createSelector(selectUi, (state) => state.userInfo);

export const selectSessionExpired = createSelector(selectUi, (state) => state.sessionExpired);

export const selectCourseGracePeriodInputType = createSelector(
	selectUi,
	(state) => state.courseGracePeriodInputType
);

export const selectStudentExceptionGracePeriodInputType = createSelector(
	selectUi,
	(state) => state.studentExceptionGracePeriodInputType
);

export default uiReducer;
