import React, { useEffect, useId, useState } from 'react';

import { chakra, HStack, PropsOf } from '@chakra-ui/react';
import { DateTime } from 'luxon';

import { selectCourseTimeZone } from '../../api/selectors';
import { useUrlParams } from '../../hooks';
import { useAppSelector } from '../../store/hooks';
import { ISO8601Date, Time24Hour } from '../../types';
import { dueDateDueTimeToISO } from '../../utils';
import { inputStyle } from '../DueDatesTable/styles';

interface Props extends PropsOf<typeof chakra.input> {
	/**
	 * The date in YYYY-MM-DD format that this time will combine with.
	 * This is needed to show the correct time zone, since time zone can vary by date (e.g. daylight savings time).
	 */
	date: ISO8601Date;
	time: Time24Hour;
	onChange: (time: Time24Hour) => void;
}

const TimePicker: React.FC<Props> = (props) => {
	const { date, time: initialTime, onChange } = props;
	const [inputValue, setInputValue] = useState(initialTime);
	const { courseId } = useUrlParams();
	const courseTimeZone = useAppSelector((state) => selectCourseTimeZone(state, courseId));
	const formattedTimeZone = DateTime.fromISO(
		dueDateDueTimeToISO(date, initialTime, courseTimeZone),
		{ zone: courseTimeZone }
	).toFormat('ZZZZ');
	const timeZoneId = useId();

	useEffect(() => {
		setInputValue(initialTime);
	}, [initialTime]);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		setInputValue(e.target.value);
		if (e.target.validity.valid) {
			onChange(e.target.value);
		}
	};

	return (
		<HStack justifyContent="end" spacing={1}>
			<chakra.input
				type="time"
				required
				aria-label="Due time"
				sx={{
					...inputStyle,
					w: 115,
					pr: 1,
					textAlign: 'end',
					'&::-webkit-calendar-picker-indicator': {
						ml: 0.5
					}
				}}
				value={inputValue}
				onChange={handleChange}
				aria-describedby={timeZoneId}
			/>
			<span id={timeZoneId}>{formattedTimeZone}</span>
		</HStack>
	);
};

export default TimePicker;
