import { ComponentStyleConfig } from '@chakra-ui/react';

export const Tabs: ComponentStyleConfig = {
	baseStyle: {
		color: 'black'
	},
	variants: {
		line: {
			tab: {
				fontSize: 'lg',
				fontWeight: 'bold',
				py: 0,
				px: 1,
				mr: 3,
				borderBottomWidth: 1,
				borderBottomColor: 'none',
				_selected: {
					color: 'black',
					borderBottomColor: 'gray.300'
				},
				_active: {
					bg: 'none'
				}
			},
			tablist: {
				borderBottomColor: 'transparent'
			},
			tabpanel: {
				px: 1
			}
		}
	}
};
