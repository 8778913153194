import * as ActionCable from '@rails/actioncable';

import { ddmApi } from './api';
import {
	ChangeRequestProgress,
	ChangeRequestResult,
	ChangeRequestStatus,
	ChangeRequestStep
} from './api/types';
import { logError, logInfo, logWarn } from './rollbar';

import type { AppDispatch } from './store';

let consumer: ActionCable.Consumer | null = null;

interface PushNotification {
	type: string;
}

interface DueDateChangeNotification extends PushNotification {
	type: 'due_date_change_request.progress.update';
	id: string;
	progress: ChangeRequestProgress;
	status: ChangeRequestStatus;
	result: ChangeRequestResult;
	steps: ChangeRequestStep[];
}

export const setupPushNotifications = ({
	jwt,
	courseId,
	dispatch
}: {
	jwt: string;
	courseId: string;
	dispatch: AppDispatch;
}) => {
	if (!consumer) {
		consumer = ActionCable.createConsumer(`${import.meta.env.VITE_API_BASE}/cable?jwt=${jwt}`);
	}

	if (consumer.subscriptions.subscriptions.length === 0) {
		const connectOptions = {
			channel: 'PushNotificationsChannel',
			rooms: [`course:${courseId}:ddm`]
		};
		logInfo('websocket: connecting', connectOptions);
		consumer.subscriptions.create(connectOptions, {
			connected: () => {
				logInfo('websocket: connected');
			},
			disconnected: () => {
				logInfo('websocket: disconnected');
			},
			received: (data: PushNotification) => {
				logInfo('websocket: data received', data);
				if (data.type === 'due_date_change_request.progress.update') {
					const { id, progress, status, result, steps } = data as DueDateChangeNotification;
					dispatch(
						ddmApi.util.updateQueryData('getChangesIndex', Number(courseId), (draft) => {
							/** `draft` can be nullish in this callback if e.g. the change_requests fetch redirects to session_expired. */
							if (!draft) {
								logWarn("Can't apply Websocket update because getChangesIndex draft is null");
								return;
							}

							const existingDDCR = draft.requests?.find((cr) => cr.id === Number(id));
							if (!existingDDCR) {
								logWarn("Tried to update a change request that doesn't exist in draft", data);
								return;
							}

							existingDDCR.status = status;
							existingDDCR.progress = progress;
							existingDDCR.result = result;
							existingDDCR.steps = steps;
						})
					);
				}
			},
			rejected: () => {
				logError('websocket connection was rejected');
			}
		});
	}
};

export const teardownPushNotifications = () => {
	if (consumer) {
		logInfo('websocket: disconnecting');
		consumer.disconnect();
		consumer = null;
	}
};
