import React from 'react';

import { Button } from './Button';
import { Modal } from './Modal';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onContinue: () => void;
}

const SaveConfirmationModal: React.FC<Props> = React.memo((props) => {
	const { isOpen, onClose, onContinue } = props;

	return (
		<Modal
			size="xl"
			title="Are you sure?"
			isOpen={isOpen}
			onClose={onClose}
			footer={
				<>
					<Button variant="underline-blue" mr="6" size="lg" onClick={onClose}>
						Cancel
					</Button>
					<Button
						colorScheme="blue"
						size="md"
						onClick={() => {
							onContinue();
							onClose();
						}}>
						Continue
					</Button>
				</>
			}>
			If you continue, the webtext will be updated to reflect your changes, and your students will
			be notified of the changes that are relevant to them.
		</Modal>
	);
});

SaveConfirmationModal.displayName = 'SaveConfirmationModal';

export default SaveConfirmationModal;
