import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { MdOutlineSync } from 'react-icons/md';

import { VStack, Box, Flex, ColorHues, Icon, VisuallyHidden } from '@chakra-ui/react';

import { colors } from '../../theme';
import { Step } from './types';

interface Props {
	steps: Step[];
}

const StepperStep: React.FC<{ index: number; step: Step }> = (props) => {
	const { index, step } = props;
	const { label, state, percentComplete, delayed } = step;

	const brandBlue = (colors['brandBlue'] as unknown as ColorHues)[500];
	const gradient = `linear-gradient(90deg, ${brandBlue} 0%, ${brandBlue} ${percentComplete}%, rgba(255,255,255,1) ${percentComplete}%, rgba(255,255,255,1) 100%);`;

	const inProgress = state === 'in-progress';
	const completed = state === 'completed';

	return (
		<VStack
			as="li"
			sx={{
				counterIncrement: 'step-indicator',
				position: 'relative',
				fontSize: '14px',
				flex: '1 1 0%',
				_after: {
					bg: completed ? 'brandBlue.500' : 'white',
					color: completed ? 'white' : 'brandBlue.500',
					border: completed ? 'none' : '1px solid blue',
					height: '24px',
					width: '24px',
					borderRadius: 'full',
					textAlign: 'center',
					content: `counter(step-indicator)`,
					position: 'absolute',
					right: `calc(50% - 12px)`,
					top: `calc((1.5rem - 0.3rem)/-2)`,
					zIndex: 100,
					paddingTop: completed ? '2px' : '1px'
				},
				_before: {
					content: `""`,
					h: '5px',
					bg: completed ? 'brandBlue.500' : inProgress ? gradient : 'white',
					borderY: '1px solid blue',
					position: 'absolute',
					right: `calc(50% - 12px)`,
					top: 0,
					width: '100%'
				},
				_first: {
					marginLeft: { base: -1, sm: -1 },
					_before: {
						content: `none`
					}
				},
				_last: {
					marginRight: { base: -1, sm: -1 }
				}
			}}>
			<Flex as="span" alignItems="center" marginTop="6">
				{delayed && label === 'Delivering to LMS...' && (
					<Icon as={MdOutlineSync} fontSize="1.3em" mr="1" />
				)}
				{completed && index > 0 && <Icon as={FaCheck} mr="1" />}
				<span>
					{label}
					<VisuallyHidden>{` ${state || 'not started'}`}</VisuallyHidden>
				</span>
			</Flex>
		</VStack>
	);
};

const Stepper: React.FC<Props> = (props) => {
	const { steps } = props;
	return (
		<Box mx="-6" mt="8">
			<Flex as="ol" listStyleType="none" aria-label="progress">
				{steps.map((step, index) => (
					<StepperStep index={index} step={step} key={`step-${step.label}`} />
				))}
			</Flex>
		</Box>
	);
};

export default Stepper;
