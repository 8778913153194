import { useHotkeys } from 'react-hotkeys-hook';

import { getOS, OperatingSystemName } from '../utils';

export const SHORTCUTS = {
	WINDOWS: {
		drawer: 'ctrl+alt+d',
		table: 'ctrl+alt+t'
	},
	MAC: {
		drawer: 'ctrl+d',
		table: 'ctrl+t'
	}
};

type Props = {
	keys: Record<OperatingSystemName, string>;
	element: React.RefObject<HTMLElement>;
};

export function useShortcutToFocus(props: Props) {
	const { keys, element } = props;
	useHotkeys(
		keys[getOS()],
		() => {
			if (element.current) {
				element.current.focus();
			}
		},
		[element]
	);
}
