import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
	baseStyle: {
		fontWeight: 'bold',
		borderRadius: 'lg'
	},
	sizes: {
		xs: {
			px: 4,
			py: 3
		},
		sm: {
			fontSize: 'md',
			px: 8,
			py: 5
		},
		md: {
			fontSize: 'md',
			px: 8,
			py: 6
		},
		lg: {
			fontSize: 'lg',
			px: 12,
			py: 6
		},
		xl: {
			fontSize: 'xl',
			px: 20,
			py: 5
		}
	},
	variants: {
		solid: {
			bg: 'brandBlue.500',
			color: 'white',
			_hover: {
				bg: 'brandBlue.700',
				_disabled: {
					bgColor: 'gray.500'
				}
			},
			_active: {
				bg: 'brandBlue.800'
			},
			_disabled: {
				bg: 'gray.500',
				opacity: 1
			}
		},
		outline: {
			color: 'brandBlue.500',
			borderColor: 'brandBlue.500'
		},
		underline: {
			color: 'black',
			borderRadius: 0,
			borderBottom: '4px',
			borderBottomStyle: 'solid',
			borderBottomColor: 'border.800',
			py: 0,
			px: 2,
			height: '2em',
			_hover: {
				textDecoration: 'none'
			}
		},
		'underline-blue': {
			color: 'brandBlue.400',
			borderRadius: 0,
			borderBottom: '2px',
			borderBottomStyle: 'solid',
			borderBottomColor: 'brandBlue.400',
			py: 0,
			px: 0,
			height: '2em',
			_hover: {
				textDecoration: 'none'
			}
		},
		text: {
			px: 0,
			py: 1,
			borderRadius: 0
		}
	},
	defaultProps: {
		size: 'md',
		variant: 'solid'
	}
};
