import { ChakraProps } from '@chakra-ui/react';

export const highlightStyle: ChakraProps['sx'] = {
	backgroundColor: 'RGBA(255, 214, 66, 0.29)',
	outline: '2px solid #FFD642',
	outlineOffset: -2,
	fontWeight: 'bold'
};

export const inputStyle: ChakraProps['sx'] = {
	h: 37,
	p: 1.5,
	border: 'solid 1px #979797 !important',
	rounded: 'sm',
	background: 'white',
	':invalid': {
		outline: '5px auto red'
	},
	':invalid:focus-visible': {
		outline: '3px solid red'
	}
};

export const tableContainerStyle: ChakraProps['sx'] = {
	pl: { base: 4, sm: 0 },
	pb: 2,
	ml: { base: -3, sm: 'inherit' },
	mr: { base: -3, sm: 'inherit' },
	maxW: { base: '96vw', sm: '100%', lg: '875px' },
	minW: '100%'
};
