import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner, VStack } from '@chakra-ui/react';
import jwtDecode from 'jwt-decode';

import { API_URLS } from '../api';
import { logError } from '../rollbar';
import { useAppDispatch } from '../store/hooks';
import {
	setAccessToken,
	setDrawerOpen,
	setSelectedChangeRequest,
	setUserInfo,
	UserInfo
} from '../store/uiSlice';

const Welcome: React.FC = () => {
	const { searchParams } = new URL(window.location.href);
	const jwt = searchParams.get('jwt');
	const courseId = searchParams.get('courseId');
	const changeRequestId = searchParams.get('changeRequestId');

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		/**
		 * This effect can fire even after the app have been sent to `/courses`.  We need to make
		 * sure the user is on the welcome page.
		 */
		if (!window.location.href.includes('/welcome')) return;

		if (!courseId || !jwt) {
			navigate(`/error`);
			logError(new Error('Problem welcoming the user, missing JWT or course id'), {
				courseId,
				jwt
			});
		} else {
			fetch(API_URLS.ACCESS_TOKEN(courseId), {
				headers: {
					Authorization: `Bearer ${jwt}`
				}
			})
				.then((response) => {
					if (response.url.includes('session_missing=true')) {
						/**
						 * The JWT is most likely expired
						 */
						return Promise.reject();
					}
					return response.text();
				})
				.then((response) => {
					dispatch(setAccessToken(response));

					try {
						const decodedJwt = jwtDecode<Partial<{ extra: { userInfo: UserInfo } }>>(response);
						const { userInfo } = decodedJwt.extra ?? {};
						/**
						 * Set userInfo from the JWT
						 */
						if (userInfo == null) {
							logError(new Error('User information was not found inside the JWT'), {
								courseId,
								response
							});
						} else {
							dispatch(setUserInfo(userInfo));
						}
					} catch (e) {
						logError(new Error('Could not decode the JWT, Intercom will be affected'), {
							courseId,
							response
						});
					}
				})
				.then(() => {
					if (changeRequestId) {
						dispatch(
							setSelectedChangeRequest({ changeRequestId: Number(changeRequestId), deepLink: true })
						);
						dispatch(setDrawerOpen(true));
					}
					navigate(`/courses/${courseId}`, { replace: true });
				})
				.catch(() => {
					navigate('/error');
					logError(new Error('Problem fetching the access token'));
				});
		}
	}, [changeRequestId, courseId, dispatch, jwt, navigate]);

	return (
		<VStack justifyContent="center" height="100vh" flex="1">
			<Spinner alignSelf="center" size="xl" />
		</VStack>
	);
};

export default React.memo(Welcome);
