import { CgRemove } from 'react-icons/cg';

import { Tr, Td, Icon, Th, VisuallyHidden, Box } from '@chakra-ui/react';
import { DateTime } from 'luxon';

import { selectCourseTimeZone } from '../../api/selectors';
import { useUrlParams } from '../../hooks';
import { useAppSelector } from '../../store/hooks';
import { DueDatesTableRowWithDiff } from '../../store/rowSelectors';
import { selectCourseGracePeriodInputType } from '../../store/uiSlice';
import { dueDateDueTimeToISO, formatDate, getFormattedTimeFromISOWithTimeZone } from '../../utils';
import { highlightStyle } from './styles';

interface Props {
	row: DueDatesTableRowWithDiff;
}

const ReadonlyTableRow: React.FC<Props> = ({ row }) => {
	const {
		chapter,
		dueDate,
		dueTime,
		penaltyPercentage,
		penaltyPeriodInDays,
		student,
		changed,
		oldRow,
		courseRow
	} = row;
	const { courseId } = useUrlParams();
	const courseTimeZone = useAppSelector((state) => selectCourseTimeZone(state, courseId));
	const courseGracePeriodInputType = useAppSelector(selectCourseGracePeriodInputType);
	const dueISO = dueDate && dueTime ? dueDateDueTimeToISO(dueDate, dueTime, courseTimeZone) : null;

	/**
	 * When penaltyPeriodInDays is set to 0, this also removes the penalty percentage,
	 * so the two columns share the same "was removed" logic.
	 */
	const wasGracePeriodRemoved =
		changed &&
		penaltyPeriodInDays === 0 &&
		((oldRow != null && oldRow.penaltyPeriodInDays > 0) ||
			(courseRow != null && courseRow.penaltyPeriodInDays > 0));

	return (
		<Tr data-readonly="true" className={Number(chapter.number) % 2 === 0 ? 'even' : 'odd'}>
			<Th scope="row" data-student={student != null}>
				{student != null
					? `${student.firstName} ${student.lastName}`
					: `${chapter.number} ${chapter.name}`}
			</Th>
			<TdWithDiff
				changed={changed && oldRow?.dueDate !== dueDate}
				removed={
					changed && dueDate == null && (oldRow?.dueDate != null || courseRow?.dueDate != null)
				}>
				{dueISO != null && formatDate(dueISO, courseTimeZone)}
			</TdWithDiff>
			<TdWithDiff
				changed={changed && oldRow?.dueTime !== dueTime}
				removed={
					changed && dueTime == null && (oldRow?.dueTime != null || courseRow?.dueTime != null)
				}>
				{dueISO != null && getFormattedTimeFromISOWithTimeZone(dueISO, courseTimeZone)}
			</TdWithDiff>
			<TdWithDiff
				changed={changed && oldRow?.penaltyPercentage !== penaltyPercentage}
				removed={wasGracePeriodRemoved}>
				{penaltyPeriodInDays > 0 && `${penaltyPercentage} %`}
			</TdWithDiff>
			<TdWithDiff
				changed={changed && oldRow?.penaltyPeriodInDays !== penaltyPeriodInDays}
				removed={wasGracePeriodRemoved}>
				{(() => {
					if (penaltyPeriodInDays === 0) {
						return null;
					}

					if (courseGracePeriodInputType === 'number-of-days') {
						return `${penaltyPeriodInDays} day${penaltyPeriodInDays > 1 ? 's' : ''}`;
					} else if (courseGracePeriodInputType === 'end-date') {
						return formatDate(
							DateTime.fromISO(dueISO!).plus({ days: penaltyPeriodInDays }).toISO(),
							courseTimeZone
						);
					}
				})()}
			</TdWithDiff>
			<Td></Td>
		</Tr>
	);
};
export default ReadonlyTableRow;

const RemovedIcon = () => <Icon aria-label="Removed" mb="-1" mx="3" w="5" h="5" as={CgRemove} />;

const TdWithDiff: React.FC<React.PropsWithChildren<{ changed: boolean; removed: boolean }>> = ({
	changed,
	removed,
	children
}) => {
	return (
		<Td data-changed={changed || removed}>
			{changed || removed ? (
				<Box
					sx={highlightStyle}
					h="52px"
					display="flex"
					alignItems="center"
					justifyContent={removed ? 'center' : 'end'}
					px={4}>
					{removed && <RemovedIcon />}
					{!removed && changed && <VisuallyHidden>Change: </VisuallyHidden>}
					{children}
				</Box>
			) : (
				<Box px={4}>{children}</Box>
			)}
		</Td>
	);
};
