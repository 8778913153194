import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';

import { logInfo } from '../rollbar';
import { setSessionExpired } from '../store/uiSlice';
import { transformCalendarDueDates } from './helpers';

import type { RootState } from '../store';
import type {
	HydrateResponse,
	CreateChangeRequest,
	CreateChangeResponse,
	ChangesIndexResponse
} from './types';

const API_BASE = import.meta.env['VITE_API_BASE'];

export const API_URLS = {
	ACCESS_TOKEN: (courseId: string) => `${API_BASE}/api/auth/v1/token?course_id=${courseId}`,
	HYDRATE: (courseId: number) => `${API_BASE}/courses/${courseId}/change_requests/hydrate_ddm`,
	CHANGES: (courseId: number) => `${API_BASE}/courses/${courseId}/change_requests`
};

const baseQuery = fetchBaseQuery({
	baseUrl: API_BASE,
	prepareHeaders: (headers, { getState }) => {
		const { accessToken } = (getState() as RootState).ui;
		headers.set('Authorization', `Bearer ${accessToken}`);
		headers.set('Accept', 'application/json');
		return headers;
	}
});

const queryWithAuthCheck: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions
) => {
	const result = await baseQuery(args, api, extraOptions);
	if (result.error?.status === 401) {
		api.dispatch(setSessionExpired(true));
		logInfo('Token has expired');
	}
	return result;
};

export const ddmApi = createApi({
	reducerPath: 'ddmApi',
	baseQuery: queryWithAuthCheck,
	tagTypes: ['Hydrate'],
	endpoints: (builder) => ({
		getHydrate: builder.query<HydrateResponse, number>({
			providesTags: ['Hydrate'],
			query: (courseId) => API_URLS.HYDRATE(courseId),
			transformResponse: (response: HydrateResponse) => {
				response.editableCalendar.dueDates = transformCalendarDueDates(
					response.editableCalendar.dueDates
				);
				response.initialCalendar.dueDates = transformCalendarDueDates(
					response.initialCalendar.dueDates
				);

				return response;
			}
		}),
		getChangesIndex: builder.query<ChangesIndexResponse, number>({
			query: (courseId) => API_URLS.CHANGES(courseId),
			transformResponse: (response: ChangesIndexResponse) => {
				response.requests = response.requests.map((r) => {
					return {
						...r,
						changes: transformCalendarDueDates(r.changes),
						headCalendar: {
							...r.headCalendar,
							dueDates: transformCalendarDueDates(r.headCalendar.dueDates)
						},
						baseCalendar: {
							...r.baseCalendar,
							dueDates: transformCalendarDueDates(r.baseCalendar.dueDates)
						}
					};
				});
				return response;
			}
		}),
		createChanges: builder.mutation<CreateChangeResponse, CreateChangeRequest>({
			invalidatesTags: ['Hydrate'],
			query: (request) => ({
				url: API_URLS.CHANGES(request.courseId),
				method: 'POST',
				body: request.requestBody
			}),
			transformResponse: (response: CreateChangeResponse) => {
				response.head_calendar.dueDates = transformCalendarDueDates(
					response.head_calendar.dueDates
				);
				return response;
			}
		})
	})
});

export const {
	useGetHydrateQuery,
	useLazyGetHydrateQuery,
	useGetChangesIndexQuery,
	useLazyGetChangesIndexQuery,
	useCreateChangesMutation,
	usePrefetch
} = ddmApi;
