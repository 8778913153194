import React, { useMemo } from 'react';

import { UnorderedList } from '@chakra-ui/react';

import { useGetHydrateQuery } from '../../api';
import { useUrlParams } from '../../hooks';
import { selectStudentsWithExceptions } from '../../store/coursesSlice';
import { useAppSelector } from '../../store/hooks';
import { selectOpenStudentException, selectTemporaryStudentExceptions } from '../../store/uiSlice';
import StudentExceptionListItem from './StudentExceptionListItem';

const StudentExceptionList: React.FC = () => {
	const { courseId } = useUrlParams();
	const { data: hydrateDate } = useGetHydrateQuery(courseId);
	const students = hydrateDate?.students;
	const openStudentException = useAppSelector((state) => selectOpenStudentException(state));
	const temporaryStudentExceptions = useAppSelector(selectTemporaryStudentExceptions);
	const studentsWithExceptions = useAppSelector((state) =>
		selectStudentsWithExceptions(state, courseId)
	);

	const allStudentsWithExceptionsIncludingTemporaries = useMemo(() => {
		return [...new Set([...studentsWithExceptions, ...temporaryStudentExceptions])].sort(
			(a, b) => a - b
		);
	}, [studentsWithExceptions, temporaryStudentExceptions]);

	if (!students) return null;

	return (
		// `role={undefined}` here removes a useless `role="list"` added by Chakra
		<UnorderedList w="100%" role={undefined}>
			{allStudentsWithExceptionsIncludingTemporaries.map((studentId) => {
				const student = students[studentId];
				if (student) {
					return (
						<StudentExceptionListItem
							key={`student-${studentId}`}
							studentId={Number(studentId)}
							temporary={!studentsWithExceptions.has(Number(studentId))}
							open={openStudentException === Number(studentId)}>
							{student.fullName}
						</StudentExceptionListItem>
					);
				}
			})}
		</UnorderedList>
	);
};

export default StudentExceptionList;
