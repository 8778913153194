import { FiAlertTriangle } from 'react-icons/fi';

import { Heading, Text, VStack } from '@chakra-ui/react';

import ContactInfo from './ContactInfo';

const ErrorMessage: React.FC = () => {
	return (
		<VStack spacing={5} alignItems="start" maxW="80ch" ml={24} fontSize="lg">
			<Heading
				mt={24}
				sx={{
					display: 'grid',
					gridTemplateColumns: '36px 1fr',
					marginLeft: '-45px',
					alignItems: 'center',
					gap: '8px'
				}}>
				<FiAlertTriangle color="#ed0404" />
				An error has occurred.
			</Heading>

			<Text>
				In order to continue, please close this browser window or tab and then relaunch the due date
				manager from the Tools Menu of your webtext.
			</Text>

			<Text>
				If the error persists, use the Soomo Messenger button in the lower right corner of the
				screen to contact Soomo Support.
			</Text>

			<ContactInfo />
		</VStack>
	);
};
export default ErrorMessage;
