import { useId } from 'react';

import { HStack } from '@chakra-ui/react';
import { DateTime } from 'luxon';

import { selectCourseTimeZone } from '../../api/selectors';
import { useUrlParams } from '../../hooks';
import { useAppSelector } from '../../store/hooks';
import {
	selectCourseGracePeriodInputType,
	selectStudentExceptionGracePeriodInputType
} from '../../store/uiSlice';
import { ISO8601Date } from '../../types';
import DatePicker from './DatePicker';
import NumberInput, { NumberInputProps } from './NumberInput';

const MAX_GRACE_PERIOD_DURATION_IN_DAYS = 99;

interface Props extends NumberInputProps {
	dueDate: ISO8601Date;
	tableType: 'course-level' | 'student-exceptions';
}

const PenaltyPeriodLengthInput: React.FC<Props> = ({
	dueDate,
	tableType,
	onChange,
	value,
	...rest
}) => {
	const unitId = useId();
	const { courseId } = useUrlParams();
	const courseTimeZone = useAppSelector((state) => selectCourseTimeZone(state, courseId));
	const gracePeriodInputType = useAppSelector(
		tableType === 'course-level'
			? selectCourseGracePeriodInputType
			: selectStudentExceptionGracePeriodInputType
	);

	if (gracePeriodInputType === 'end-date') {
		const gracePeriodEndDate = DateTime.fromISO(dueDate).plus({ days: value }).toISODate();
		return (
			<DatePicker
				aria-label="Grace period end date"
				date={gracePeriodEndDate}
				onChange={(newGracePeriodEndDate) => {
					const diff = DateTime.fromISO(newGracePeriodEndDate).diff(
						DateTime.fromISO(dueDate),
						'days'
					);
					onChange(diff.days);
				}}
				min={DateTime.fromISO(dueDate, { zone: courseTimeZone }).plus({ days: 1 }).toISODate()}
				max={DateTime.fromISO(dueDate, { zone: courseTimeZone })
					.plus({ days: MAX_GRACE_PERIOD_DURATION_IN_DAYS })
					.toISODate()}
			/>
		);
	}

	return (
		<HStack justifyContent="end" spacing={1}>
			<NumberInput
				value={value}
				onChange={onChange}
				min={1}
				max={MAX_GRACE_PERIOD_DURATION_IN_DAYS}
				size={2}
				aria-label="Grace period (days after due)"
				aria-describedby={unitId}
				{...rest}
			/>
			<span id={unitId}>
				day<span style={{ visibility: value === 1 ? 'hidden' : undefined }}>s</span>
			</span>
		</HStack>
	);
};
export default PenaltyPeriodLengthInput;
