import React, { useRef } from 'react';

import { Thead, Tr, Th, Text, VStack, Select } from '@chakra-ui/react';

import { useShortcutToFocus, SHORTCUTS } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
	GracePeriodInputType,
	selectCourseGracePeriodInputType,
	selectStudentExceptionGracePeriodInputType,
	setCourseGracePeriodInputType,
	setStudentExceptionGracePeriodInputType
} from '../../store/uiSlice';

interface Props {
	tableType: 'course-level' | 'student-exceptions';
}

export const TableHead: React.FC<Props> = ({ tableType }) => {
	const headingRef = useRef<HTMLTableSectionElement>(null);
	const dispatch = useAppDispatch();
	const gracePeriodInputType = useAppSelector(
		tableType === 'course-level'
			? selectCourseGracePeriodInputType
			: selectStudentExceptionGracePeriodInputType
	);

	useShortcutToFocus({
		keys: {
			Windows: SHORTCUTS.WINDOWS.table,
			Mac: SHORTCUTS.MAC.table,
			Other: SHORTCUTS.WINDOWS.table
		},
		element: headingRef
	});

	const handleGracePeriodInputTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		dispatch(
			tableType === 'course-level'
				? setCourseGracePeriodInputType(e.target.value as GracePeriodInputType)
				: setStudentExceptionGracePeriodInputType(e.target.value as GracePeriodInputType)
		);
	};

	return (
		<Thead ref={headingRef} tabIndex={-1}>
			<Tr>
				<Th scope="col" pl="0" minW="64">
					Chapters
				</Th>
				<Th scope="col" textAlign="right" w="150px">
					Due Date
				</Th>
				<Th scope="col" textAlign="right" w="165px">
					Due Time
				</Th>
				<Th scope="col" textAlign="right" whiteSpace="nowrap">
					% Deduction
				</Th>
				<Th scope="col" textAlign="right" whiteSpace="nowrap">
					<VStack w="130px" align="flex-end" spacing={0}>
						<Text as="span">Grace Period</Text>
						<Select
							value={gracePeriodInputType}
							onChange={handleGracePeriodInputTypeChange}
							aria-label="Grace period input type"
							size="xs"
							variant="gracePeriodInputType">
							<option value="number-of-days"># of days</option>
							<option value="end-date">end date</option>
						</Select>
					</VStack>
				</Th>
				<Th scope="col" textAlign="right">
					Actions
				</Th>
			</Tr>
		</Thead>
	);
};
