import { useId } from 'react';

import { HStack } from '@chakra-ui/react';

import NumberInput, { NumberInputProps } from './NumberInput';

const PenaltyPercentageInput: React.FC<NumberInputProps> = ({ value, onChange, ...rest }) => {
	const unitId = useId();

	return (
		<HStack justifyContent="end" spacing={1}>
			<NumberInput
				value={value}
				onChange={onChange}
				min={0}
				max={99}
				size={2}
				aria-label="Penalty %"
				aria-describedby={unitId}
				{...rest}
			/>
			<span id={unitId}>%</span>
		</HStack>
	);
};
export default PenaltyPercentageInput;
