import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { selectHydrateData } from '../api/selectors';

import type { RootState } from '.';
import type { DueDate, DueDateChange, StudentExceptionDueDate } from '../types';

type FamilyId = string;
type CalendarId = number;
type StudentId = number;
type CourseId = number;

export type DueDateCollection = {
	[chapterFamilyId: FamilyId]: DueDate[];
};

type ChangesCollection = {
	[chapterFamilyIdStudentId: string]: DueDateChange;
};

interface Draft {
	dueDates: DueDateCollection;
	changes: ChangesCollection;
	headCalendarId?: CalendarId;
}

interface Course {
	draft: Draft;
}

interface CoursesState {
	[courseId: number]: Course;
}

const initialState: CoursesState = {};

type UpdateDueDatePayload = {
	courseId: CourseId;
	update: DueDate;
};

interface CopyStudentExceptionPayload {
	courseId: CourseId;
	studentId: StudentId;
	source: StudentExceptionDueDate;
}

const coursesSlice = createSlice({
	name: 'courses',
	initialState,
	reducers: {
		/**
		 * Update an existing due date or student exception
		 */
		updateDueDate: (state, action: PayloadAction<UpdateDueDatePayload>) => {
			/**
			 * Each "[chapterFamilyId: FamilyId]: DueDate[];" should contain on DueDate for the
			 * chapter.  It can contain main StudentExceptions which are just DueDates with a
			 * studentId attribute
			 */
			const { courseId, update } = action.payload;
			const courseDueDates = state[courseId].draft.dueDates;

			if (typeof update.studentId === 'number') {
				/**
				 * See if we already have a student exception for this chapter & student
				 */
				const hasDueDate = courseDueDates[update.familyId].find(
					(dd) => dd.studentId === update.studentId
				);

				if (hasDueDate) {
					/**
					 * Update student exception
					 */
					courseDueDates[update.familyId] = courseDueDates[update.familyId].map((dueDate) =>
						dueDate.studentId === update.studentId ? update : dueDate
					);

					/**
					 * Store the changes
					 */
					state[courseId].draft.changes[`${update.familyId}:${update.studentId}`] = {
						...update
					};
				}
			} else {
				/**
				 * Update existing course-level due date for the chapter
				 */
				courseDueDates[update.familyId] = courseDueDates[update.familyId].map((dueDate) =>
					!dueDate.studentId && dueDate.familyId === update.familyId ? update : dueDate
				);

				/**
				 * Store the changes
				 */
				state[courseId].draft.changes[update.familyId] = {
					...update
				};
			}
		},
		/**
		 * Create a new student exception based on the course level due date
		 *
		 * @param action
		 */
		createStudentException: (state, action: PayloadAction<UpdateDueDatePayload>) => {
			const { courseId, update } = action.payload;
			const courseDueDates = state[courseId].draft.dueDates;

			/**
			 * Add the new student exception to the course
			 */
			courseDueDates[update.familyId].push(update);

			/**
			 * Store the new exception in the changes array
			 */
			state[courseId].draft.changes[`${update.familyId}:${update.studentId}`] = update;
		},
		/**
		 * Add a new student exception
		 *
		 * @param action contains the `studentId` we are intending on adding the exception and an
		 * existing student exception row to base the new exception off of
		 */
		copyStudentException: (state, action: PayloadAction<CopyStudentExceptionPayload>) => {
			const { courseId, studentId, source } = action.payload;

			const copy = { ...source };
			copy.studentId = studentId;

			state[courseId].draft.dueDates[copy.familyId].push(copy);

			/**
			 * Store the new exception in the changes array
			 */
			state[courseId].draft.changes[`${copy.familyId}:${studentId}`] = copy;
		},
		/**
		 * Remove a student exception for a chapter or remove all student exceptions for a specific
		 * student
		 *
		 * @param action If `chapterFamilyId` is provided this will remove a specific student
		 * exception. If `chapterFamilyId` is not provided this will remove all student exceptions
		 * for the course.
		 */
		removeStudentExceptions: (
			state,
			action: PayloadAction<{
				studentId: StudentId;
				courseId: CourseId;
				chapterFamilyId?: FamilyId;
			}>
		) => {
			const { studentId, courseId, chapterFamilyId } = action.payload;

			if (chapterFamilyId) {
				/**
				 * Filter out all exceptions with this chapter and student id combo
				 */
				state[courseId].draft.dueDates[chapterFamilyId] = state[courseId].draft.dueDates[
					chapterFamilyId
				].filter((dd) => dd?.studentId !== studentId);

				/**
				 * No student exception in the changes collection to remove, this means we are
				 * removing an existing student exception that the server is aware of.
				 */
				if (!state[courseId].draft.changes[`${chapterFamilyId}:${studentId}`]) {
					state[courseId].draft.changes[`${chapterFamilyId}:${studentId}`] = {
						familyId: chapterFamilyId,
						studentId: studentId,
						penaltyFactor: 0,
						penaltyPeriodLength: 0,
						due: null,
						_destroy: true
					};
				} else {
					/**
					 * Remove deleted student exception from changes, this is an exception that has
					 * never been sent to the server.
					 */
					delete state[courseId].draft.changes[`${chapterFamilyId}:${studentId}`];
				}
			} else {
				for (const chapterId in state[courseId].draft.dueDates) {
					/**
					 * Filter out all exceptions with this chapter and student id combo
					 */
					state[courseId].draft.dueDates[chapterId] = state[courseId].draft.dueDates[
						chapterId
					].filter((dd) => {
						/**
						 * Check if this is a student exception for the current student
						 */
						if (studentId === dd.studentId) {
							if (!state[courseId].draft.changes[`${chapterId}:${studentId}`]) {
								/**
								 * No student exception in the changes collection to remove, this
								 * means we are removing an existing student exception that the
								 * server is aware of.
								 */
								state[courseId].draft.changes[`${chapterId}:${studentId}`] = {
									familyId: chapterId,
									studentId: studentId,
									penaltyFactor: 0,
									penaltyPeriodLength: 0,
									due: null,
									_destroy: true
								};
							} else {
								/**
								 * Remove deleted student exception from changes, this is an
								 * exception that has never been sent to the server.
								 */
								delete state[courseId].draft.changes[`${chapterId}:${studentId}`];
							}
						}

						return dd?.studentId !== studentId;
					});
				}
			}
		},
		/**
		 * Reset the courses due dates draft to the due date collection provided in the action
		 * payload.
		 */
		resetDueDates: (
			state,
			action: PayloadAction<{
				courseId: number;
				dueDateCollection: DueDateCollection;
				headCalendarId: number;
			}>
		) => {
			const { courseId, dueDateCollection, headCalendarId } = action.payload;

			/**
			 * If the store is completely fresh, we need to initialize the course entry
			 */
			if (!state[courseId]) {
				state[courseId] = {
					draft: {
						changes: {},
						dueDates: {}
					}
				};
			}

			const courseDraft = state[courseId].draft;

			/**
			 * Empty changes collection
			 */
			courseDraft.changes = {};

			/**
			 * Reset draft due dates to what it was based on in the history
			 */
			courseDraft.headCalendarId = headCalendarId;
			courseDraft.dueDates = dueDateCollection;
		}
	}
});

export const {
	updateDueDate,
	createStudentException,
	removeStudentExceptions,
	copyStudentException,
	resetDueDates
} = coursesSlice.actions;

const coursesSliceReducer = coursesSlice.reducer;

const selectCourses = (state: RootState): CoursesState => state.courses;

export const selectCourse = createSelector(
	[selectCourses, (_state, courseId: number) => courseId],
	// We *must* manually set the return type as Course | undefined, because this hook is called
	// before we've even made a request to the hydrate_ddm endpoint, meaning that `courses` will be empty
	// the first time this selector is executed. (Otherwise, tsc will infer the return type of
	// `selectCourse` as just `Course`, which leads to runtime errors in subsequent chained selectors
	// because of attempts to access properties on `undefined`.)
	(courses, courseId) => courses[courseId] as Course | undefined
);

/**
 * Selects all due dates and student exceptions for a course
 */
const selectAllDueDatesForCourse = createSelector(selectCourse, (course) =>
	course?.draft?.dueDates
		? Object.keys(course.draft.dueDates).flatMap((chapterKey) => course.draft.dueDates[chapterKey])
		: []
);

/**
 * Selects just the student exceptions for a course
 */
export const selectStudentExceptions = createSelector(selectAllDueDatesForCourse, (dueDates) =>
	dueDates.filter((dd) => dd.studentId)
);

/**
 * Select student id's from students with exceptions
 */
export const selectStudentsWithExceptions = createSelector(
	[selectStudentExceptions, (_, __, chapterFamilyId?: string) => chapterFamilyId],
	(dueDates, chapterFamilyId) =>
		new Set<number>(
			(
				(chapterFamilyId != null
					? dueDates.filter((dd) => dd.familyId === chapterFamilyId && dd.studentId != null)
					: dueDates.filter((dd) => dd.studentId != null)) as StudentExceptionDueDate[]
			).map((dd) => dd.studentId)
		)
);

/**
 * Reduce the list of all due dates down to an object that is keyed by student id. Each entry
 * contains and array of exceptions for that student.
 */
export const selectStudentExceptionsByStudent = createSelector(
	selectStudentExceptions,
	(dueDates) =>
		dueDates.reduce<{ [studentId: string]: DueDate[] }>((acc, dd) => {
			if (!dd.studentId) return acc;

			if (acc[dd.studentId]) {
				return { ...acc, [dd.studentId]: [...acc[dd.studentId], dd] };
			} else {
				return { ...acc, [dd.studentId]: [dd] };
			}
		}, {})
);

export const selectChanges = createSelector(selectCourse, (course) =>
	course?.draft?.changes != null ? Object.values(course.draft.changes) : []
);

export const selectDraftHeadCalendarId = createSelector(
	selectCourse,
	(course) => course?.draft?.headCalendarId
);

/**
 * Returns the list of students to show as options in a student select dropdown.
 *
 * This occurs in two places:
 * - in `DuplicateStudentExceptionModal`, when duplicating a student exception onto another student
 * - in `AllDueDatesEditableTableRow`, when the row is a student exception. In this case, a `StudentSelect` dropdown is shown
 *   that initially has the current student for that row as the selected option. The user can then change the selected option
 *   to move the student exception to a different student.
 *
 * @returns an array of `Student`s that includes:
 * - the current student for this student exception
 * - all other students that don't already have a student exception for this course and chapter
 *
 * sorted alphabetically by last name.
 */
export const selectStudentSelectOptions = createSelector(
	[
		selectCourse,
		selectHydrateData,
		(_state, _courseId, chapterFamilyId: string) => chapterFamilyId,
		(_state, _courseId, _chapterFamilyId, currentStudentId: number) => currentStudentId
	],
	(course, hydrateData, chapterFamilyId, currentStudentId) => {
		if (!course || !hydrateData) {
			return [];
		}
		const { students } = hydrateData;
		const chapterDueDates = course.draft.dueDates[chapterFamilyId] ?? [];
		const studentIdsWithExceptionForThisChapter = new Set(
			chapterDueDates.map((dd) => dd.studentId).filter((id) => id != null) as number[]
		);

		return Object.values(students)
			.filter(
				(student) =>
					student.studentId === currentStudentId ||
					!studentIdsWithExceptionForThisChapter.has(student.studentId)
			)
			.sort((a, b) => a.lastName.localeCompare(b.lastName));
	}
);

export default coursesSliceReducer;
