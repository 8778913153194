import React from 'react';

import {
	Modal as ChModal,
	ModalProps,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter
} from '@chakra-ui/react';

interface Props extends ModalProps {
	variant?: 'solid' | 'outline' | 'underline' | 'text';
	showCloseButton?: boolean;
	footer?: React.ReactNode;
	title?: string;
}

const Modal: React.FC<Props> = (props) => {
	const { showCloseButton, footer, title, isOpen, onClose, children } = props;

	return (
		<ChModal blockScrollOnMount={false} {...props} isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				{title && <ModalHeader>{title}</ModalHeader>}
				{showCloseButton && <ModalCloseButton />}
				<ModalBody>{children}</ModalBody>
				{footer && <ModalFooter>{footer}</ModalFooter>}
			</ModalContent>
		</ChModal>
	);
};

export default Modal;
