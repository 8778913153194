import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
	parts: ['field'],
	baseStyle: {
		field: {
			borderColor: 'black',
			color: 'black',
			_placeholder: {
				color: '#444'
			}
		}
	},
	sizes: {
		sm: {
			field: {
				fontSize: '1rem',
				py: 4,
				height: '40px',
				borderRadius: 'md'
			}
		},
		md: {
			field: {
				fontSize: '1rem',
				py: 6
			}
		}
	},
	defaultProps: {
		size: 'md'
	}
};
