import { createSelector } from '@reduxjs/toolkit';

import { ddmApi } from './api';

import type { RootState } from '../store';

export const selectHydrateData = (state: RootState, courseId: number) =>
	ddmApi.endpoints.getHydrate.select(courseId)(state).data;

export const selectChangesIndexEndpoint = (state: RootState, courseId: number) =>
	ddmApi.endpoints.getChangesIndex.select(courseId)(state);

export const selectChangesIndexData = createSelector(
	selectChangesIndexEndpoint,
	(changesIndexEndpoint) => changesIndexEndpoint.data
);

export const selectCourseProperties = createSelector(selectHydrateData, (hydrateData) => {
	return hydrateData?.course ?? null;
});

export const selectCourseTimeZone = createSelector(
	selectCourseProperties,
	(courseProperties) => courseProperties?.timeZone ?? ''
);

export const selectStudent = createSelector(
	[selectHydrateData, (_state, _courseId, studentId: number) => studentId],
	(hydrateData, studentId) => hydrateData?.students?.[studentId]
);
