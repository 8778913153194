import { Text, Link } from '@chakra-ui/react';

// from @soomo-libs/src/properties/support.ts
export const supportPhone = '888-240-0314';
export const supportEmail = 'support@soomolearning.com';

const ContactInfo: React.FC = () => {
	return (
		<Text>
			You can also call us at{' '}
			<Link href={`tel:${supportPhone}`} target="_blank">
				{supportPhone}
			</Link>
			, or email us at{' '}
			<Link href={`mailto:${supportEmail}`} target="_blank">
				{supportEmail}
			</Link>
			.
		</Text>
	);
};

export default ContactInfo;
