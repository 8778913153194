import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { NavBar } from './components';
import { useFullStoryInitialize } from './hooks/fullstory';
import { useBootIntercom } from './hooks/intercom';
import { Welcome, Error, Course, PageNotFound } from './pages';

const Router: React.FC = () => {
	/**
	 * This hook will boot up intercom once per mount.  Doing this here so
	 * that intercom can be booted properly between page refreshes.
	 */
	useBootIntercom();

	useFullStoryInitialize();

	return (
		<BrowserRouter>
			<NavBar />
			<Routes>
				<Route path="/error" element={<Error />} />
				<Route path="/welcome" element={<Welcome />} />
				<Route path="/courses/:courseId" element={<Course />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
