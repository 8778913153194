import { Spinner, Table, Tbody, TableContainer } from '@chakra-ui/react';

import { useGetHydrateQuery } from '../../api';
import { selectStudent } from '../../api/selectors';
import { useUrlParams } from '../../hooks';
import { useAppSelector } from '../../store/hooks';
import { selectStudentExceptionsRows } from '../../store/rowSelectors';
import StudentExceptionRow from './StudentExceptionsTableRow';
import { tableContainerStyle } from './styles';
import { TableHead } from './TableHead';

interface Props {
	studentId: number;
}

export const StudentExceptionsTable: React.FC<Props> = (props) => {
	const { studentId } = props;
	const { courseId } = useUrlParams();
	const { isLoading: hydrateIsLoading } = useGetHydrateQuery(courseId);
	const rows = useAppSelector((state) => selectStudentExceptionsRows(state, courseId, studentId));
	const student = useAppSelector((state) => selectStudent(state, courseId, studentId))!;

	if (hydrateIsLoading) {
		return <Spinner alignSelf="center" size="xl" />;
	}

	return (
		<TableContainer sx={tableContainerStyle}>
			<Table aria-label={`Due date exceptions for ${student.fullName}`}>
				<TableHead tableType="student-exceptions" />
				<Tbody>
					{rows.map((row) => (
						<StudentExceptionRow studentId={studentId} key={row.chapter.familyId} row={row} />
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
export default StudentExceptionsTable;
