import React, { useMemo, useState } from 'react';
import { FaCheck, FaTimes, FaMinus, FaAngleDown, FaAngleUp } from 'react-icons/fa';

import { Box, ChakraProps, Flex, Icon, Text, chakra, PropsOf } from '@chakra-ui/react';

import { useGetHydrateQuery } from '../../api';
import { ChangeRequest } from '../../api/types';
import { useUrlParams } from '../../hooks';
import { useAppDispatch } from '../../store/hooks';
import { setSelectedChangeRequest } from '../../store/uiSlice';
import { formatDateWithTimeAndTimeZone } from '../../utils';
import { drawerItemStyles } from './styles';

interface Props extends PropsOf<typeof chakra.button> {
	request: ChangeRequest;
	buttonRef: React.RefObject<HTMLButtonElement> | null;
}

const HistoryItem: React.FC<Props> = (props) => {
	const { request, buttonRef, ...rest } = props;
	const dispatch = useAppDispatch();
	const { courseId } = useUrlParams();
	const { data: hydrateData } = useGetHydrateQuery(courseId);
	const timeZone = hydrateData?.course.timeZone;
	const [whatFailedOpen, setWhatFailedOpen] = useState(false);

	const displayMessage = useMemo(() => {
		if (!request.result.completedAt) return;
		const completedAtFormatted = formatDateWithTimeAndTimeZone(
			request.result.completedAt,
			timeZone
		);
		if (request.result.status === 'fully-delivered') {
			return `Successfully delivered to LMS ${completedAtFormatted}`;
		} else if (request.result.status === 'completed') {
			return `Successfully completed ${completedAtFormatted}`;
		} else if (request.result.status === 'canceled') {
			return `Canceled by Soomo ${completedAtFormatted}`;
		} else if (request.result.status === 'partially-delivered') {
			return `Partially delivered to LMS ${completedAtFormatted}`;
		}
	}, [request.result.completedAt, request.result.status, timeZone]);

	const displayIcon = useMemo(() => {
		if (request.result?.status === 'fully-delivered' || request.result?.status === 'completed') {
			return FaCheck;
		} else if (request.result.status === 'canceled') {
			return FaTimes;
		} else if (request.result.status === 'partially-delivered') {
			return FaMinus;
		}
	}, [request]);

	const hasMessageAndIcon = displayMessage && displayIcon;

	return (
		<Box as="li" className="history-item">
			<chakra.button
				sx={styles}
				ref={buttonRef}
				onClick={() => {
					dispatch(setSelectedChangeRequest({ changeRequestId: request.id }));
				}}
				{...rest}>
				<Text
					as="div"
					fontSize="sm"
					fontWeight={props['aria-pressed'] === 'true' ? 'bold' : 'normal'}>
					{`${request?.changes.length} change${
						request?.changes.length > 1 ? 's' : ''
					} submitted ${formatDateWithTimeAndTimeZone(request.requestDate, timeZone)} by ${
						request?.requester.name
					}`}
				</Text>
				{hasMessageAndIcon && (
					<Flex alignItems="center">
						<Icon fontSize="1.1em" as={displayIcon} mr="1" />
						{displayMessage}
						{request.result?.status === 'partially-delivered' && (
							<Flex
								as="button"
								alignItems="center"
								onClick={() => setWhatFailedOpen(!whatFailedOpen)}
								aria-expanded={whatFailedOpen}
								aria-controls={`request-${request.id}-what-failed`}
								sx={{
									textDecoration: 'underline',
									color: 'brandBlue.500',
									ml: 2
								}}>
								What failed?
								<Icon fontSize="1.2em" as={whatFailedOpen ? FaAngleDown : FaAngleUp} mr="1" />
							</Flex>
						)}
					</Flex>
				)}
				<Box as="ol" id={`request-${request.id}-what-failed`} textAlign="left">
					{whatFailedOpen &&
						request.result?.problems?.map((problem) => (
							<Text
								key={problem}
								as="li"
								sx={{
									listStyleType: 'none',
									mb: 0.5
								}}>
								{problem}
							</Text>
						))}
				</Box>
			</chakra.button>
		</Box>
	);
};

const styles: ChakraProps['sx'] = {
	...drawerItemStyles,
	borderBottomColor: 'transparent',
	fontSize: 'sm',
	display: 'flex',
	flexDirection: 'column'
};

export default HistoryItem;
