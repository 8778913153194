import React, { useEffect, useRef } from 'react';

import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter
} from '@chakra-ui/react';

import { useGetHydrateQuery } from '../api';
import { useUrlParams } from '../hooks';
import { resetDraftToHeadCalendar } from '../store/actions';
import { useAppDispatch } from '../store/hooks';
import { Button } from './Button';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
}

const ConflictAlert: React.FC<Props> = React.memo((props) => {
	const { isOpen, onClose } = props;

	const dispatch = useAppDispatch();
	const { courseId } = useUrlParams();
	const getHydrateResult = useGetHydrateQuery(courseId);
	const headerRef = useRef<HTMLElement>(null);

	const onCloseModal = () => {
		/**
		 * Need to do more than reloading here
		 */
		location.reload();
	};

	useEffect(() => {
		/**
		 * After we get the new hydrate data, reset the state to head calendar before forcing the
		 * user to reload the page.
		 */
		if (getHydrateResult.isSuccess && courseId && isOpen) {
			dispatch(resetDraftToHeadCalendar({ courseId: Number(courseId) }));
		}
	}, [
		courseId,
		dispatch,
		getHydrateResult.isSuccess,
		getHydrateResult?.data?.chapters,
		getHydrateResult?.data?.editableCalendar.id,
		isOpen
	]);

	return (
		<AlertDialog
			size="lg"
			isCentered
			isOpen={isOpen}
			onClose={onClose}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			leastDestructiveRef={headerRef}>
			<AlertDialogOverlay backdropFilter="blur(6px)">
				<AlertDialogContent>
					<AlertDialogHeader fontWeight="bold" ref={headerRef} tabIndex={-1}>
						A conflict has occurred trying to save your changes.
					</AlertDialogHeader>

					<AlertDialogBody>
						The page must be refreshed to get the most up to date course data.
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button size="md" onClick={onCloseModal}>
							Refresh the page
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
});

ConflictAlert.displayName = 'ConflictAlert';

export default ConflictAlert;
