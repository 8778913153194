import React, { useMemo } from 'react';

import { Box, ChakraProps, Link, Text, chakra, PropsOf } from '@chakra-ui/react';

import { useGetHydrateQuery } from '../../api';
import { ChangeRequest } from '../../api/types';
import { useUrlParams } from '../../hooks';
import { logWarn } from '../../rollbar';
import { useAppDispatch } from '../../store/hooks';
import { setSelectedChangeRequest } from '../../store/uiSlice';
import { formatDate, getFormattedTimeFromISO } from '../../utils';
import { supportEmail } from '../ContactInfo';
import { Stepper } from '../Stepper';
import { drawerItemStyles } from './styles';
import { getStepperState } from './utils';

interface Props extends PropsOf<typeof chakra.button> {
	request: ChangeRequest;
	buttonRef: React.RefObject<HTMLButtonElement> | null;
}

const StatusItem: React.FC<Props> = (props) => {
	const { request, buttonRef, ...rest } = props;
	const dispatch = useAppDispatch();
	const { courseId } = useUrlParams();
	const { data: hydrateData } = useGetHydrateQuery(courseId);
	const timeZone = hydrateData?.course.timeZone;
	if (timeZone == null) {
		logWarn('hydrateData.course.timeZone is null');
	}
	const requestTimeFormatted = getFormattedTimeFromISO(request?.requestDate, timeZone!);
	const requestDateFormatted = formatDate(request?.requestDate, timeZone);
	const stepperState = useMemo(() => getStepperState(request), [request]);

	return (
		<chakra.button
			ref={buttonRef}
			onClick={() => {
				dispatch(setSelectedChangeRequest({ changeRequestId: request.id }));
			}}
			sx={styles}
			{...rest}>
			<Text fontSize="md" fontWeight="semibold">
				Status: {request.status === 'queued' ? 'Queued' : 'Processing'}
			</Text>
			<Text as="span" fontSize="sm" fontWeight="normal">
				{`Initiated ${requestDateFormatted} at ${requestTimeFormatted} by ${request.requester.name}`}
			</Text>
			{stepperState.length > 0 && <Stepper steps={stepperState} />}
			{stepperState.find((s) => s.delayed) && (
				<Box mt="8">
					<Text as="div" fontSize="sm">
						We haven&rsquo;t been able to update your LMS yet. Still trying...
					</Text>
					<Link
						sx={{
							fontSize: 'sm',
							fontWeight: 'medium'
						}}
						href={`mailto:${supportEmail}`}
						target="_blank">
						Contact Soomo for help
					</Link>
				</Box>
			)}
		</chakra.button>
	);
};

const styles: ChakraProps['sx'] = {
	...drawerItemStyles,
	overflow: 'hidden',
	borderColor: 'border.50',
	borderInlineColor: 'transparent'
};

export default StatusItem;
