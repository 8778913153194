import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { ChakraProvider } from '@chakra-ui/react';
import { Settings } from 'luxon';

import { logError } from './rollbar';
import Router from './Router';
import { store } from './store';
import { theme } from './theme';

const container = document.getElementById('root')!;
const root = createRoot(container);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((window as any).IS_PLAYWRIGHT || import.meta.env['DEV']) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(window as any).TEST_REDUX_STORE = store;
}

window.addEventListener('unhandledrejection', (e) => {
	logError(e.reason, { promise: e.promise });
});

window.addEventListener('error', (e) => {
	logError(e.error);
});

/**
 * Set Luxon's default locale to english.
 */
Settings.defaultLocale = 'en';

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<ChakraProvider theme={theme}>
				<Router />
			</ChakraProvider>
		</Provider>
	</React.StrictMode>
);
