import React from 'react';
import { FaEllipsisH } from 'react-icons/fa';

import { Menu, MenuButton, IconButton, Icon, MenuList, MenuItem } from '@chakra-ui/react';

import { useUrlParams } from '../../hooks';
import {
	addDueDate,
	addPenaltyPeriod,
	removeDueDate,
	removePenaltyPeriod
} from '../../store/actions';
import { removeStudentExceptions } from '../../store/coursesSlice';
import { useAppDispatch } from '../../store/hooks';
import { DueDatesTableRow } from '../../store/rowSelectors';
import { setDuplicatingStudentException } from '../../store/uiSlice';

interface Props {
	row: DueDatesTableRow;
}

const AllDueDatesActionsMenu: React.FC<Props> = ({ row }) => {
	const { chapter, student, dueDate, penaltyPeriodInDays } = row;
	const { courseId } = useUrlParams();
	const dispatch = useAppDispatch();

	const handleDuplicate =
		student != null
			? () => {
					dispatch(setDuplicatingStudentException(row));
			  }
			: null;

	const handleAddDueDate =
		dueDate == null
			? () => {
					dispatch(
						addDueDate({
							chapterFamilyId: chapter.familyId,
							studentId: student?.studentId ?? null,
							courseId
						})
					);
			  }
			: null;

	const handleRemoveCourseDueDate =
		student == null && dueDate != null
			? () => {
					dispatch(
						removeDueDate({
							chapterFamilyId: chapter.familyId,
							studentId: null,
							courseId
						})
					);
			  }
			: null;

	const handleDeleteStudentException =
		student != null
			? () => {
					dispatch(
						removeStudentExceptions({
							courseId,
							studentId: student.studentId,
							chapterFamilyId: chapter.familyId
						})
					);
			  }
			: null;

	const handleAddPenaltyPeriod =
		dueDate && penaltyPeriodInDays === 0
			? () => {
					dispatch(
						addPenaltyPeriod({
							chapterFamilyId: chapter.familyId,
							studentId: student?.studentId ?? null,
							courseId
						})
					);
			  }
			: null;

	const handleRemoveCoursePenaltyPeriod =
		student == null && dueDate && penaltyPeriodInDays > 0
			? () => {
					dispatch(
						removePenaltyPeriod({
							chapterFamilyId: chapter.familyId,
							studentId: null,
							courseId
						})
					);
			  }
			: null;

	const disabled = [
		handleDuplicate,
		handleAddDueDate,
		handleRemoveCourseDueDate,
		handleDeleteStudentException,
		handleAddPenaltyPeriod,
		handleRemoveCoursePenaltyPeriod
	].every((fn) => fn == null);

	return (
		<Menu>
			<MenuButton
				disabled={disabled}
				aria-label="Open actions menu"
				as={IconButton}
				icon={<Icon as={FaEllipsisH} />}
				variant="text"
				sx={{
					_active: {
						bg: 'brandBlue.100'
					}
				}}
			/>
			<MenuList>
				{handleDuplicate && <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>}
				{handleAddDueDate && <MenuItem onClick={handleAddDueDate}>Add due date</MenuItem>}
				{handleAddPenaltyPeriod && (
					<MenuItem onClick={handleAddPenaltyPeriod}>Add grace period</MenuItem>
				)}
				{handleRemoveCoursePenaltyPeriod && (
					<MenuItem onClick={handleRemoveCoursePenaltyPeriod}>Remove grace period</MenuItem>
				)}
				{handleRemoveCourseDueDate && (
					<MenuItem onClick={handleRemoveCourseDueDate}>Remove due date</MenuItem>
				)}
				{handleDeleteStudentException && (
					<MenuItem onClick={handleDeleteStudentException}>Revert to course setting</MenuItem>
				)}
			</MenuList>
		</Menu>
	);
};

export default AllDueDatesActionsMenu;
