import { Tr, Th, Td } from '@chakra-ui/react';

import { useUrlParams } from '../../hooks';
import { createOrUpdate, moveStudentException } from '../../store/actions';
import { useAppDispatch } from '../../store/hooks';
import { DueDatesTableRow, StudentExceptionRow } from '../../store/rowSelectors';
import {
	DatePicker,
	PenaltyPercentageInput,
	PenaltyPeriodLengthInput,
	StudentSelect,
	TimePicker
} from '../Input';
import AllDueDatesActionsMenu from './AllDueDatesActionsMenu';
import useMinMaxDate from './useMinMaxDate';

interface Props {
	row: DueDatesTableRow;
}

const EditableTableRow: React.FC<Props> = ({ row }) => {
	const { chapter, dueDate, dueTime, penaltyPercentage, penaltyPeriodInDays, student } = row;
	const { courseId } = useUrlParams();
	const dispatch = useAppDispatch();
	const { minDate, maxDate } = useMinMaxDate(courseId);

	const handleChange = (update: Partial<DueDatesTableRow>) => {
		dispatch(
			createOrUpdate({
				courseId,
				studentId: student?.studentId ?? null,
				chapterFamilyId: chapter.familyId,
				update
			})
		);
	};

	const handleStudentChange = (destinationStudentId: number) => {
		dispatch(
			moveStudentException({
				courseId,
				sourceRow: row as StudentExceptionRow,
				destinationStudentId
			})
		);
	};

	return (
		<Tr className={Number(chapter.number) % 2 === 0 ? 'even' : 'odd'}>
			<Th scope="row" data-student={student != null}>
				{student != null ? (
					<StudentSelect
						value={student.studentId}
						chapterFamilyId={chapter.familyId}
						onChange={handleStudentChange}
					/>
				) : (
					`${chapter.number} ${chapter.name}`
				)}
			</Th>
			<Td>
				{dueDate != null && (
					<DatePicker
						aria-label="Due date"
						date={dueDate}
						onChange={(date) => handleChange({ dueDate: date })}
						min={minDate}
						max={maxDate}
					/>
				)}
			</Td>
			<Td>
				{dueDate != null && dueTime != null && (
					<TimePicker
						date={dueDate}
						time={dueTime}
						onChange={(time) => handleChange({ dueTime: time })}
					/>
				)}
			</Td>
			<Td>
				{penaltyPeriodInDays > 0 && (
					<PenaltyPercentageInput
						value={penaltyPercentage}
						onChange={(value) => handleChange({ penaltyPercentage: value })}
					/>
				)}
			</Td>
			<Td>
				{penaltyPeriodInDays > 0 && (
					<PenaltyPeriodLengthInput
						dueDate={dueDate!}
						tableType="course-level"
						value={penaltyPeriodInDays}
						onChange={(value) => handleChange({ penaltyPeriodInDays: value })}
					/>
				)}
			</Td>
			<Td>
				<AllDueDatesActionsMenu row={row} />
			</Td>
		</Tr>
	);
};
export default EditableTableRow;
