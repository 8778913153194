import { DateTime } from 'luxon';

import { CourseDueDate, DueDate, StudentExceptionDueDate } from '../types';
import { dueDateDueTimeToISO, get24HourTimeFromISO } from '../utils';
import { CourseRow, DueDatesTableRow, StudentExceptionRow } from './rowSelectors';

export const computeCourseDueDatesUpdate = (props: {
	studentId: number | null;
	update: Partial<DueDatesTableRow> & { _destroy?: boolean };
	chapterDueDates: DueDate[];
	timeZone: string;
	lastDayOfClass: string;
}) => {
	const { studentId, update, chapterDueDates, timeZone, lastDayOfClass } = props;
	let isNewStudentException = false;

	let baseDueDate = chapterDueDates.find(
		(dd) => (!studentId && !dd.studentId) || dd.studentId === studentId
	);

	/**
	 * If our first `.find` fails and we have a `studentId` then we will just base the update on
	 * the course level chapter due date.
	 *
	 * We are computing an update for a new student exception here
	 */
	if (!baseDueDate && studentId) {
		baseDueDate = chapterDueDates.find((dd) => !dd.studentId);
		isNewStudentException = true;
	}

	if (baseDueDate) {
		const updatedDueDate: DueDate & { _destroy?: boolean } = { ...baseDueDate };

		if (update.dueTime) {
			if (baseDueDate.due == null) {
				updatedDueDate.due = null;
			} else {
				const baseISODate = baseDueDate.due.slice(0, 10);
				updatedDueDate.due = dueDateDueTimeToISO(baseISODate, update.dueTime, timeZone);
			}
		}

		if (update.dueDate) {
			if (baseDueDate.due == null) {
				updatedDueDate.due = update.dueDate;
			} else {
				const dueTimeFromBaseDueDate = get24HourTimeFromISO(
					baseDueDate.due || lastDayOfClass,
					timeZone
				);
				updatedDueDate.due = dueDateDueTimeToISO(update.dueDate, dueTimeFromBaseDueDate, timeZone);
			}
		} else if (update.dueDate === null) {
			updatedDueDate.due = null;
		}

		if (update.penaltyPercentage != null) {
			updatedDueDate.penaltyFactor = update.penaltyPercentage;
		}

		if (update.penaltyPeriodInDays != null) {
			updatedDueDate.penaltyPeriodLength = update.penaltyPeriodInDays;
		}

		if (update._destroy != null) {
			updatedDueDate._destroy = update._destroy;
		}

		if (isNewStudentException) {
			updatedDueDate.studentId = studentId;
		}

		return { updatedDueDate: updatedDueDate, isNewStudentException };
	}
};

export function rowToDueDate(row: CourseRow, courseTimeZone: string): CourseDueDate;
export function rowToDueDate(
	row: StudentExceptionRow,
	courseTimeZone: string
): StudentExceptionDueDate;
export function rowToDueDate(row: DueDatesTableRow, courseTimeZone: string): DueDate {
	return {
		due:
			row.dueDate == null || row.dueTime == null
				? null
				: dueDateDueTimeToISO(row.dueDate, row.dueTime, courseTimeZone),
		familyId: row.familyId,
		penaltyFactor: row.penaltyPercentage,
		penaltyPeriodLength: row.penaltyPeriodInDays,
		studentId: row.student?.studentId ?? null
	};
}

/**
 * Get student exceptions that happen before the chapter level due date's due field.
 */
export function getEarlyStudentExceptions(
	chapterDueDate: DueDate | undefined,
	chapterDueDates: DueDate[],
	timeZone?: string
) {
	if (!chapterDueDate || !chapterDueDate.due) return [];
	const newDue = DateTime.fromISO(chapterDueDate.due, { zone: timeZone });
	return chapterDueDates.filter((dd) => {
		/**
		 * If the new chapter level due date is after the student exceptions due date
		 */
		return dd.due && dd.studentId && newDue >= DateTime.fromISO(dd.due, { zone: timeZone });
	}) as StudentExceptionDueDate[];
}
