import React from 'react';

import { VStack, Heading, Text, Link } from '@chakra-ui/react';

import { supportEmail } from '../components/ContactInfo';

const PageNotFound: React.FC = () => {
	return (
		<VStack spacing={5} alignItems="start" maxW="60ch" ml={[0, 24]} px={[8, 0]} fontSize="lg">
			<Heading mt={[12, 24]}>Page not found</Heading>

			<Text>
				Looking for the Due Date Manager? Head back to{' '}
				<Link href="https://www.webtexts.com/" target="_blank">
					your webtext
				</Link>
				, open the Tools menu, and click the &#39;Manage Due Dates&#39; link.
			</Text>

			<Text>
				If you need help,{' '}
				<Link href={`mailto:${supportEmail}`} target="_blank">
					contact us
				</Link>
				.
			</Text>
		</VStack>
	);
};

export default PageNotFound;
