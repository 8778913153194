import {
	FullStory,
	init as initFullStory,
	isInitialized as isFullStoryInitialized
} from '@fullstory/browser';
import { useEffect } from 'react';

import { useAppSelector } from '../store/hooks';
import { selectUserInfo } from '../store/uiSlice';

const { VITE_FULLSTORY_ORG_ID } = import.meta.env;

export const useFullStoryInitialize = () => {
	const userInfo = useAppSelector(selectUserInfo);

	useEffect(() => {
		if (!VITE_FULLSTORY_ORG_ID) return;
		if (isFullStoryInitialized()) return;
		if (!userInfo.fullstoryEnabled) return;

		initFullStory({ orgId: VITE_FULLSTORY_ORG_ID });
		FullStory('setIdentity', {
			uid: String(userInfo.userId),
			properties: userInfo.fullstoryProperties
		});
	}, [userInfo]);
};
