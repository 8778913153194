import {
	Box,
	HStack,
	Spinner,
	Table,
	TableCaption,
	Tbody,
	Text,
	TableContainer
} from '@chakra-ui/react';

import { useGetHydrateQuery } from '../../api';
import { useUrlParams } from '../../hooks';
import { useAppSelector } from '../../store/hooks';
import {
	DueDatesTableRow,
	DueDatesTableRowWithDiff,
	selectAllDueDatesRows
} from '../../store/rowSelectors';
import { selectDrawerOpen, selectEditing } from '../../store/uiSlice';
import EditableTableRow from './AllDueDatesEditableTableRow';
import ReadonlyTableRow from './AllDueDatesReadonlyTableRow';
import { highlightStyle, tableContainerStyle } from './styles';
import { TableHead } from './TableHead';

export const AllDueDatesTable: React.FC = () => {
	const { courseId } = useUrlParams();
	const editable = useAppSelector(selectEditing);
	const drawerOpen = useAppSelector(selectDrawerOpen);
	const { isLoading: hydrateIsLoading } = useGetHydrateQuery(courseId);
	const rows = useAppSelector((state) => selectAllDueDatesRows(state, courseId));

	if (hydrateIsLoading) {
		return <Spinner alignSelf="center" size="xl" />;
	}

	return (
		<TableContainer sx={tableContainerStyle}>
			<Table aria-label="Course due dates">
				{drawerOpen && (
					<TableCaption textAlign={'left'} pl="0" color="black">
						<Text as="span" fontWeight={'bold'} mb="1.5">
							Key:
						</Text>
						<HStack>
							<Box sx={highlightStyle} display={'inline-block'} p="4" />
							<Text as="span" fontWeight={'normal'}>
								Highlights changes that were saved in the selected update.
							</Text>
						</HStack>
					</TableCaption>
				)}

				<TableHead tableType="course-level" />

				<Tbody>
					{rows.map((row) => {
						// We'll allow literal 'undefined' here in the key
						const key = `${row.familyId}-${row.student?.studentId}`;
						/**
						 * @todo These type assertions are only safe as long as editing === !drawerOpen;
						 * if we decide to implement T-51853, this will no longer be the case
						 */
						return editable ? (
							<EditableTableRow key={key} row={row as DueDatesTableRow} />
						) : (
							<ReadonlyTableRow key={key} row={row as DueDatesTableRowWithDiff} />
						);
					})}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
export default AllDueDatesTable;
