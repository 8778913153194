import { useState } from 'react';

import { useCombobox } from 'downshift';

export interface UseTypeaheadProps {
	name: string;
	label: string;
	items: string[];
	value: string;
	setValue: (value: string) => void;
	maxItems?: number;
}

export function useTypeahead(props: UseTypeaheadProps) {
	const { items, value, setValue, maxItems } = props;

	const [inputItems, setInputItems] = useState(items);

	const inputItemsToDisplay = !maxItems ? inputItems : inputItems?.slice(0, maxItems) || [];

	const {
		isOpen,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		getItemProps,
		highlightedIndex,
		reset
	} = useCombobox({
		items: inputItemsToDisplay,
		initialSelectedItem: value,
		onInputValueChange: ({ inputValue }) => {
			if (typeof inputValue === 'string') {
				setValue(inputValue);
				setInputItems(
					items.filter(
						(item) => inputValue && item.toLowerCase().includes(String(inputValue).toLowerCase())
					)
				);
			}
		},
		onSelectedItemChange: ({ selectedItem }) => {
			setValue(String(selectedItem));
		}
	});

	return {
		// useCombobox
		isOpen,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		getItemProps,
		highlightedIndex,
		reset,
		// custom props
		inputItemsToDisplay
	} as const;
}
