import React from 'react';

import {
	Box,
	ChakraProps,
	FormControl,
	FormLabel,
	HStack,
	Input,
	UnorderedList
} from '@chakra-ui/react';

import { useTypeahead } from '../../hooks';
import { Button } from '../Button';

interface Props {
	name: string;
	label: string;
	items: string[];
	value: string;
	setValue: (value: string) => void;
	disabled?: boolean;
	formControlStyles?: ChakraProps['sx'];
	onAddClick: () => void;
}

const Typeahead: React.FC<Props> = (props) => {
	const { name, label, items, value, setValue, disabled, formControlStyles, onAddClick } = props;

	const {
		isOpen,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		getItemProps,
		highlightedIndex,
		inputItemsToDisplay,
		reset
	} = useTypeahead({ name, label, items, value, setValue });

	const showBorder = Boolean(isOpen && value && inputItemsToDisplay.length);

	return (
		<HStack w="100%" align="flex-end">
			<FormControl {...getComboboxProps()} sx={formControlStyles} position="relative">
				<FormLabel htmlFor={name} {...getLabelProps()}>
					{label}
				</FormLabel>
				<Input {...getInputProps()} name={name} disabled={disabled} size="sm" />
				<UnorderedList
					ml="0"
					zIndex="popover"
					sx={typeaheadListStyles(showBorder)}
					{...getMenuProps()}>
					{isOpen &&
						inputItemsToDisplay.map((item, index) => (
							<Box
								as="li"
								key={`${item}-${index}`}
								{...getItemProps({
									key: item,
									index,
									item
								})}
								sx={typeaheadItemStyles(highlightedIndex, index)}>
								{item}
							</Box>
						))}
				</UnorderedList>
			</FormControl>
			<Button
				size="sm"
				onClick={() => {
					onAddClick();
					reset();
				}}>
				Add
			</Button>
		</HStack>
	);
};

const typeaheadListStyles = (showBorder?: boolean) =>
	({
		mt: 2,
		position: 'absolute',
		w: '100%',
		maxHeight: '15.35rem',
		overflowY: 'scroll',
		borderColor: 'black',
		borderWidth: showBorder ? 1 : 0,
		borderRadius: 'sm'
	} as ChakraProps['sx']);

const typeaheadItemStyles = (highlightedIndex: number, index: number) =>
	({
		backgroundColor: highlightedIndex === index ? '#eeeeee' : 'white',
		p: 3,
		borderColor: 'black',
		borderBottomWidth: 1,
		listStyle: 'none',
		_last: {
			borderBottomWidth: 0
		}
	} as ChakraProps['sx']);

export default Typeahead;
