import { createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { AppThunk, AppThunkApi } from '.';
import { ddmApi } from '../api';
import { logError, logWarn } from '../rollbar';
import { StudentExceptionDueDate } from '../types';
import {
	copyStudentException,
	createStudentException,
	removeStudentExceptions,
	resetDueDates,
	updateDueDate
} from './coursesSlice';
import { computeCourseDueDatesUpdate, getEarlyStudentExceptions } from './helpers';
import { DueDatesTableRow, StudentExceptionRow } from './rowSelectors';
import { setEarlyStudentExceptions } from './uiSlice';

import type { DueDateCollection } from './coursesSlice';

const DEFAULT_PENALTY_PERIOD_LENGTH_IN_DAYS = 7;
const DEFAULT_PENALTY_PERCENTAGE = 50;

type RowActionPayload = {
	courseId: number;
	chapterFamilyId: string;
	studentId: number | null;
};

type MoveStudentExceptionPayload = {
	courseId: number;
	sourceRow: StudentExceptionRow;
	destinationStudentId: number;
};

type CreateOrUpdatePayload = RowActionPayload & {
	update: Partial<DueDatesTableRow> & {
		_destroy?: true;
	};
};

export const createOrUpdate =
	(actionPayload: CreateOrUpdatePayload): AppThunk =>
	(dispatch, getState) => {
		const { courseId, chapterFamilyId, studentId, update: rowUpdate } = actionPayload;
		const state = getState();
		const hydrateResult = ddmApi.endpoints.getHydrate.select(courseId)(state);
		const { data: hydrateData } = hydrateResult;

		/**
		 * We need the data from the hydrate call to proceed
		 */
		if (!hydrateData) {
			logError(new Error('Hydrate data was null'), actionPayload);
			return;
		}

		const courseDueDates = state.courses[courseId].draft.dueDates;

		const computedUpdate = computeCourseDueDatesUpdate({
			studentId,
			update: rowUpdate,
			chapterDueDates: courseDueDates[chapterFamilyId],
			timeZone: hydrateData.course.timeZone,
			lastDayOfClass:
				hydrateData.academicTerm?.dates.lastDayOfClass ??
				DateTime.fromJSDate(new Date(), { zone: hydrateData.course.timeZone }).toISO()
		});

		/**
		 * We need the computed update to proceed
		 */
		if (!computedUpdate) {
			logError(new Error('Computed update was null'), actionPayload);
			return;
		}

		const { updatedDueDate, isNewStudentException } = computedUpdate;

		/**
		 * Check this change for any early student exceptions
		 */
		if (updatedDueDate.due && !updatedDueDate.studentId) {
			const exceptionsInQuestion = getEarlyStudentExceptions(
				updatedDueDate,
				state.courses[courseId].draft.dueDates[updatedDueDate.familyId],
				hydrateData.course.timeZone
			);
			if (exceptionsInQuestion.length > 0) {
				dispatch(setEarlyStudentExceptions(exceptionsInQuestion));
			}
		}

		if (!isNewStudentException) {
			/**
			 * Update, this will handle existing student exceptions & course level due dates
			 */
			dispatch(
				updateDueDate({
					courseId,
					update: updatedDueDate
				})
			);
		} else {
			/**
			 * Create
			 */
			dispatch(
				createStudentException({
					courseId,
					update: updatedDueDate
				})
			);
		}
	};

export const addDueDate =
	(actionPayload: RowActionPayload): AppThunk =>
	(dispatch, getState) => {
		const { courseId, chapterFamilyId, studentId } = actionPayload;
		const state = getState();
		const hydrateResult = ddmApi.endpoints.getHydrate.select(courseId)(state);
		const { data } = hydrateResult;
		if (!data) return;

		dispatch(
			createOrUpdate({
				courseId,
				chapterFamilyId,
				studentId,
				update: {
					dueDate:
						data.academicTerm?.dates.lastDayOfClass ??
						DateTime.fromJSDate(new Date(), { zone: data.course.timeZone }).toISO()
				}
			})
		);
	};

export const removeDueDate =
	(actionPayload: RowActionPayload): AppThunk =>
	(dispatch) => {
		const { courseId, chapterFamilyId, studentId } = actionPayload;

		dispatch(
			createOrUpdate({
				courseId,
				chapterFamilyId,
				studentId,
				update: {
					dueDate: null,
					penaltyPercentage: 0,
					penaltyPeriodInDays: 0,
					/**
					 * If this is a course-level due date, then removing it means destroying the record,
					 * so we should send `_destroy: true` to core.
					 *
					 * However, if this is a student exception, then we're **creating** a student exception
					 * with a null due date *just for that student* while the course-level due date remains,
					 * and thus we should NOT pass `_destroy: true`.
					 */
					_destroy: studentId == null ? true : undefined
				}
			})
		);
	};

export const addPenaltyPeriod =
	(actionPayload: RowActionPayload): AppThunk =>
	(dispatch) => {
		const { courseId, chapterFamilyId, studentId } = actionPayload;

		dispatch(
			createOrUpdate({
				courseId,
				chapterFamilyId,
				studentId,
				update: {
					penaltyPeriodInDays: DEFAULT_PENALTY_PERIOD_LENGTH_IN_DAYS,
					penaltyPercentage: DEFAULT_PENALTY_PERCENTAGE
				}
			})
		);
	};

export const removePenaltyPeriod =
	(actionPayload: RowActionPayload): AppThunk =>
	(dispatch) => {
		const { courseId, chapterFamilyId, studentId } = actionPayload;

		dispatch(
			createOrUpdate({
				courseId,
				chapterFamilyId,
				studentId,
				update: {
					penaltyPeriodInDays: 0,
					penaltyPercentage: 0
				}
			})
		);
	};

export const moveStudentException =
	(actionPayload: MoveStudentExceptionPayload): AppThunk =>
	(dispatch, getState) => {
		const { courseId, sourceRow, destinationStudentId } = actionPayload;
		const { chapter, student } = sourceRow;
		const state = getState();
		const chapterDueDates = state.courses[courseId].draft.dueDates[chapter.familyId];
		const sourceDueDate = chapterDueDates.find((dd) => dd.studentId === student.studentId);

		if (!sourceDueDate) {
			logWarn("Tried to moveStudentException but couldn't find the source due date", actionPayload);
			return;
		}

		/**
		 * Create a copy of the student exception
		 */
		dispatch(
			copyStudentException({
				courseId,
				studentId: destinationStudentId,
				source: sourceDueDate as StudentExceptionDueDate
			})
		);

		/**
		 * Remove the source due date
		 */
		dispatch(
			removeStudentExceptions({
				studentId: student.studentId,
				courseId,
				chapterFamilyId: sourceRow.familyId
			})
		);
	};

export const resetDraftToHeadCalendar =
	(actionPayload: { courseId: number; requestId?: string }): AppThunk =>
	(dispatch, getState) => {
		const { courseId, requestId } = actionPayload;
		const state = getState();

		const hydrateResult = ddmApi.endpoints.getHydrate.select(courseId)(state);
		const { data: hydrateData } = hydrateResult;

		if (requestId) {
			const createChangeRequestResult = ddmApi.endpoints.createChanges.select(requestId)(state);

			if (!createChangeRequestResult.data) {
				logWarn(
					"Tried to resetDraftToHeadCalendar but couldn't find the change request",
					actionPayload
				);
				return;
			}

			const { head_calendar } = createChangeRequestResult.data;

			/**
			 * Prepare due dates from new head calendar
			 */
			const headCalendarDueDates = head_calendar.dueDates.reduce<DueDateCollection>((acc, dd) => {
				if (acc[dd.familyId]) {
					return { ...acc, [dd.familyId]: [...acc[dd.familyId], dd] };
				}
				return { ...acc, [dd.familyId]: [dd] };
			}, {});

			/**
			 * Hydrate the draft
			 */
			dispatch(
				resetDueDates({
					courseId,
					dueDateCollection: headCalendarDueDates,
					headCalendarId: head_calendar.id
				})
			);
		} else if (hydrateData) {
			/**
			 * This will be a standard reset.  Like when the user is being welcomed for instance.
			 */
			const headCalendarDueDates = hydrateData.editableCalendar.dueDates.reduce<DueDateCollection>(
				(acc, dd) => {
					if (acc[dd.familyId]) {
						return { ...acc, [dd.familyId]: [...acc[dd.familyId], dd] };
					}
					return { ...acc, [dd.familyId]: [dd] };
				},
				{}
			);

			dispatch(
				resetDueDates({
					courseId,
					dueDateCollection: headCalendarDueDates,
					headCalendarId: hydrateData.editableCalendar.id
				})
			);
		}
	};

export const discardChanges = createAsyncThunk<void, number, AppThunkApi>(
	'discardChanges',
	async (courseId: number, { dispatch }) => {
		// force a refetch of hydrate_ddm when discarding changes; this ensures that we restore to the correct head calendar
		// see T-52457
		await dispatch(ddmApi.endpoints.getHydrate.initiate(courseId, { forceRefetch: true }));
		dispatch(resetDraftToHeadCalendar({ courseId }));
	}
);
