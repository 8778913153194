import { defineStyleConfig } from '@chakra-ui/react';

export const Select = defineStyleConfig({
	baseStyle: {
		field: {
			outlineOffset: 0,
			'&:focus-visible': {
				outlineWidth: '5px',
				outlineStyle: 'auto',
				outlineColor: ['Highlight', '-webkit-focus-ring-color']
			}
		}
	},
	variants: {
		gracePeriodInputType: {
			field: {
				color: 'brandBlue.500',
				borderWidth: 0,
				borderBottomWidth: '1px',
				textAlign: 'right',
				w: 'fit-content',
				pl: 0
			},
			icon: {
				mr: '-10px'
			}
		}
	}
});
