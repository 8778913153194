import { extendTheme, Colors } from '@chakra-ui/react';

import * as components from './components';

export const colors: Colors = {
	brand: {
		// Blues
		900: '#1a365d',
		800: '#666666',
		700: '#2a69ac',
		// Greens
		600: '#a1b728',
		500: '#bdd53d',
		// Purple
		400: '#7201eb'
	},
	brandBlue: {
		800: '#02018a',
		700: '#0302b8',
		500: '#0403e6',
		400: '#2700F3',
		200: '#7779f1',
		100: '#D2C6F7'
	},
	background: {
		900: '#111111',
		800: '#444444',
		700: '#666666',
		600: '#cccccc',
		500: '#ECE9E9',
		200: '#f5f5f5',
		100: '#ffffff'
	},
	border: {
		900: '#111111',
		800: '#25292c',
		400: '#444444',
		300: '#666666',
		200: '#777777',
		100: '#979797',
		50: '#C4C1C1'
	},
	gray: {
		50: '#212529',
		100: '#F8F8F8',
		300: '#C8C8C8',
		400: '#ECECEC',
		500: '#878787',
		600: '#8B8B8B'
	},
	purple: {
		100: '#E6E2F9',
		700: '#3900F3'
	}
};

export const theme = extendTheme({
	colors,
	components: {
		...components
	},
	fonts: {
		body: 'SuisseIntl'
	}
});
