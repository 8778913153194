import { createSelector } from '@reduxjs/toolkit';

import { selectHydrateData, selectChangesIndexEndpoint } from '../api/selectors';
import { logWarn } from '../rollbar';
import { isoToDueDateDueTime } from '../utils';
import { selectCourse } from './coursesSlice';

import type { RootState } from '.';
import type { Chapter, DueDate, FamilyId, ISO8601Date, Student, Time24Hour } from '../types';

interface BaseRow {
	familyId: FamilyId;
	chapter: Chapter;
	dueDate: ISO8601Date | null;
	dueTime: Time24Hour | null;
	/**
	 * The penalty percentage (ranges from 0 to 100).
	 * Note: 0 is a valid value.
	 */
	penaltyPercentage: number;
	/**
	 * The penalty period / grace period length in days.
	 * 0 indicates no penalty period.
	 */
	penaltyPeriodInDays: number;
}

export interface CourseRow extends BaseRow {
	student: null;
}

export interface StudentExceptionRow extends BaseRow {
	student: Student;
}

export type DueDatesTableRow = CourseRow | StudentExceptionRow;

export type DueDatesTableRowWithDiff = DueDatesTableRow &
	(
		| {
				/**
				 * Whether this row was modified in this due date change request.
				 */
				changed: false;
				/**
				 * The previous version of this row.
				 * Only present if `changed` is true.
				 */
				oldRow?: never;
				/**
				 * The corresponding course-level due date in the **head calendar** if this is a student exception.
				 *
				 * Only present if `changed` is true.
				 */
				courseRow?: never;
		  }
		| {
				/**
				 * Whether this row was modified in this due date change request.
				 */
				changed: true;
				/**
				 * The previous version of this row.
				 *
				 * Only present if `changed` is true.
				 */
				oldRow: DueDatesTableRow | null;
				/**
				 * The corresponding course-level due date in the **head calendar** if this is a student exception.
				 *
				 * Only present if `changed` is true.
				 */
				courseRow?: DueDatesTableRow;
		  }
	);

/**
 * Returns an array of rows to display when the All Due Dates tabpanel is open.
 *
 * Rows are sorted by chapter number. Course-level due dates are presented first,
 * and then if "Show student exceptions" is checked, student exceptions sorted by student last name
 * are presented after.
 *
 * If the changes history drawer is open, then the rows returned also include diff information
 * based on the selected due date change request.
 *
 * @returns An array of `DueDateTableRow`s.
 */
export const selectAllDueDatesRows = createSelector(
	[
		(state: RootState) => {
			const { showStudentExceptions, selectedChangeRequest, drawerOpen } = state.ui;
			return { showStudentExceptions, selectedChangeRequest, drawerOpen };
		},
		selectCourse,
		selectHydrateData,
		selectChangesIndexEndpoint
	],
	(
		{ showStudentExceptions, selectedChangeRequest, drawerOpen },
		course,
		hydrateData,
		changeRequestsEndpoint
	): (DueDatesTableRow | DueDatesTableRowWithDiff)[] => {
		if (!hydrateData || !course) {
			return [];
		}
		const { students, chapters, initialCalendar } = hydrateData;
		const courseTimeZone = hydrateData.course.timeZone;
		const { data: changeRequestsData } = changeRequestsEndpoint;
		const selectedDDCR =
			drawerOpen && selectedChangeRequest > 0
				? changeRequestsData?.requests.find((cr) => cr.id === selectedChangeRequest)
				: null;
		/**
		 * 3 cases:
		 *
		 * 1. The changes history drawer is open, selectedChangeRequest is 0, and the change requests index fetch has completed.
		 *
		 *    In this case, we are showing read-only due dates from the `initialCalendar`.
		 *    We always show student exceptions when the drawer is open, so they are included.
		 *
		 *    Note that we will fall through to case 3 if the change requests index fetch has not yet completed,
		 *    even though `initialCalendar` data is available. This is to avoid the case where a user is editing the course draft,
		 *    opens the drawer for the first time, then momentarily sees the initial course calendar before it switches again
		 *    to the latest change request's headCalendar when the change requests index fetch completes.
		 *
		 * 2. The changes history drawer is open, selectedChangeRequest is > 0, and the change requests index fetch has completed.
		 *
		 *    In this case, we are showing read-only due dates from the selected change request's `headCalendar`
		 *    and highlight the cells that were changed compared to that selected change request's `baseCalendar`.
		 *    We always show student exceptions when the drawer is open, so they are included.
		 *
		 *    Note that we will fall through to case 3 if the change requests index fetch has not yet completed
		 *    in order to avoid showing a blank table when the drawer is first opened, or showing possibly stale
		 *    changes index data before the fetch completes.
		 *
		 * 3. The changes history drawer is closed.
		 *
		 *    In this case, we are showing editable due dates from the draft state (`courses[courseId].draft`).
		 *    We include student exceptions if `showStudentExceptions` is true; otherwise, we only show
		 *    course-level due dates.
		 */
		let dueDates: DueDate[] = [];
		if (drawerOpen && selectedChangeRequest === 0 && !changeRequestsEndpoint.isLoading) {
			// case 1: make a copy so we can sort it later (`initialCalendar.dueDates` is frozen)
			dueDates = [...initialCalendar.dueDates];
		} else if (drawerOpen && selectedChangeRequest > 0 && !changeRequestsEndpoint.isLoading) {
			// case 2: make a copy since we'll mutate it later (see below; `headCalendar.dueDates` is frozen)
			dueDates = [...(selectedDDCR?.headCalendar.dueDates ?? [])];
			// we need to also consider the fact that some student exception `DueDate`s may have been deleted in this change request;
			// if that's the case, then we need to manually add those `DueDate`s back with null / 0 values,
			// because they will not be present anymore in the headCalendar but we still want to show in the diff view
			// that they have been deleted in this selected change request.

			// first, create a map of all `StudentExceptionDueDate`s in the change request `headCalendar`
			const headCalendarStudentExceptionMap: { [key: string]: DueDate } = {};
			(selectedDDCR?.headCalendar.dueDates ?? []).forEach((headCalendarDueDate) => {
				if (headCalendarDueDate.studentId != null) {
					headCalendarStudentExceptionMap[
						`${headCalendarDueDate.familyId}-${headCalendarDueDate.studentId}`
					] = headCalendarDueDate;
				}
			});

			// now compare the change request `baseCalendar`'s student exception due dates against those in the `headCalendar`,
			// and add blank `studentExceptionDueDate`s for any student exceptions present in `baseCalendar` but missing in `headCalendar`
			(selectedDDCR?.baseCalendar.dueDates ?? []).forEach((baseCalendarDueDate) => {
				if (
					baseCalendarDueDate.studentId != null &&
					headCalendarStudentExceptionMap[
						`${baseCalendarDueDate.familyId}-${baseCalendarDueDate.studentId}`
					] == null
				) {
					dueDates.push({
						familyId: baseCalendarDueDate.familyId,
						studentId: baseCalendarDueDate.studentId,
						due: null,
						penaltyFactor: 0,
						penaltyPeriodLength: 0
					});
				}
			});
		} else {
			// case 3
			// flatten a Map<FamilyId, DueDate[]> into a DueDate[], filtering out student exceptions if necessary
			// (no need to copy `course.draft.dueDates` since we're calling `Object.values` on it anyway)
			dueDates = Object.values(course.draft.dueDates).flatMap((dd) =>
				showStudentExceptions ? dd : dd.filter((dd) => dd.studentId == null)
			);
		}

		const sortedDueDates = dueDates
			.filter((dd) => {
				const chapterFamilyId = dd.familyId;
				if (chapters[chapterFamilyId] == null) {
					logWarn('Found a due date without a corresponding chapter', dd);
					return false;
				}

				if (dd.studentId != null && !students[dd.studentId]) {
					console.warn('Found a due date without a corresponding student', dd);
					return false;
				}

				return true;
			})
			.sort((a, b) => {
				const chapterA = chapters[a.familyId];
				const chapterB = chapters[b.familyId];
				const studentALastName = a.studentId != null ? students[a.studentId].lastName : '';
				const studentBLastName = b.studentId != null ? students[b.studentId].lastName : '';
				return (
					chapterA.number - chapterB.number ||
					(a.studentId ?? 0) - (b.studentId ?? 0) ||
					studentALastName.localeCompare(studentBLastName)
				);
			});

		const changesMap = selectedDDCR != null ? dueDateArrayToMap(selectedDDCR.changes) : {};
		const baseCalendarMap =
			selectedDDCR != null ? dueDateArrayToMap(selectedDDCR.baseCalendar.dueDates) : {};
		const headCalendarMap =
			selectedDDCR != null ? dueDateArrayToMap(selectedDDCR.headCalendar.dueDates) : {};

		const rows: Array<DueDatesTableRow | DueDatesTableRowWithDiff> = sortedDueDates.map(
			(dueDate) => {
				const baseRow: DueDatesTableRow = dueDateToDueDateTableRow({
					dueDate,
					courseTimeZone,
					chapter: chapters[dueDate.familyId],
					student: dueDate.studentId != null ? students[dueDate.studentId] : null
				});

				// if the drawer is closed, we don't need to show changes data, so return a `DueDatesTableRow` immediately.
				if (!drawerOpen) {
					return baseRow;
				}

				// otherwise, look in the changesMap to see if this row changed in this DDCR...
				const change = changesMap[`${dueDate.familyId}-${dueDate.studentId}`];
				if (change == null) {
					// this row didn't change, so we can return a `DueDatesTableRowWithDiff` with `changed: false` immediately.
					return {
						...baseRow,
						changed: false
					};
				}

				// this row *did* change, so we need to find out what the `oldRow` was,
				// and if this row is a student exception, we also need to find what the `courseRow` is
				// (the corresponding course-level row in the **head** calendar for this chapter.)
				const oldDueDate = baseCalendarMap[`${change.familyId}-${change.studentId}`];
				const courseDueDate =
					change.studentId != null ? headCalendarMap[`${change.familyId}-null`] : null;
				return {
					...baseRow,
					changed: true,
					oldRow:
						oldDueDate != null
							? dueDateToDueDateTableRow({
									dueDate: oldDueDate,
									courseTimeZone,
									chapter: chapters[change.familyId],
									student: change.studentId != null ? students[change.studentId] : null
							  })
							: null,
					courseRow:
						courseDueDate != null
							? dueDateToDueDateTableRow({
									dueDate: courseDueDate,
									courseTimeZone,
									chapter: chapters[change.familyId],
									student: null
							  })
							: undefined
				};
			}
		);

		return rows;
	}
);

/**
 * Converts a `DueDate` to a `DueDateTableRow`. `DueDate.due` is interpreted according to the provided `courseTimeZone`.
 *
 * @param arg an object argument containing:
 * - `dueDate`: the `DueDate`
 * - `courseTimeZone`: the course's IANA time zone
 * - `chapter`: the chapter associated with this DueDate
 * - `student`: the student associated with this DueDate if it is a student exception,
 *              or `null` otherwise
 * @returns an `AllDueDatesCourseRow` if `student` is `null`,
 *       or an `AllDueDatesStudentExceptionRow` if `student` is not `null`
 */
function dueDateToDueDateTableRow(arg: {
	dueDate: DueDate;
	courseTimeZone: string;
	chapter: Chapter;
	student: null;
}): CourseRow;
function dueDateToDueDateTableRow(arg: {
	dueDate: DueDate;
	courseTimeZone: string;
	chapter: Chapter;
	student: Student;
}): StudentExceptionRow;
function dueDateToDueDateTableRow(arg: {
	dueDate: DueDate;
	courseTimeZone: string;
	chapter: Chapter;
	student: Student | null;
}): DueDatesTableRow;
function dueDateToDueDateTableRow(arg: {
	dueDate: DueDate;
	courseTimeZone: string;
	chapter: Chapter;
	student: Student | null;
}): DueDatesTableRow {
	const { dueDate: dd, courseTimeZone, chapter, student } = arg;
	const { dueDate, dueTime } =
		dd.due != null ? isoToDueDateDueTime(dd.due, courseTimeZone) : { dueDate: null, dueTime: null };
	return {
		familyId: dd.familyId,
		chapter,
		dueDate,
		dueTime,
		penaltyPercentage: dd.penaltyFactor,
		penaltyPeriodInDays: dd.penaltyPeriodLength,
		student
	};
}

/**
 * Converts a due date array into an object keyed by `${chapterFamilyId}-${studentId}`.
 * It is assumed that the due date array is unique by (chapterFamilyId, studentId).
 *
 * @param dueDates An array of `DueDate`s
 * @returns An object with keys of the form `${familyId}-${studentId}` and values of the corresponding `DueDate`
 */
const dueDateArrayToMap = (dueDates: DueDate[]) => {
	return dueDates.reduce<{ [key: string]: DueDate }>((acc, curr) => {
		const key = `${curr.familyId}-${curr.studentId}`; // allow literal "null" for student id
		// changes should be unique by chapterFamilyId and studentId
		if (acc[key] != null) {
			logWarn('Duplicated due date found in array', curr);
		}
		acc[key] = curr;
		return acc;
	}, {});
};

export interface StudentExceptionsTableRow {
	chapter: Chapter;
	courseRow: CourseRow | null;
	studentExceptionRow: StudentExceptionRow | null;
}

/**
 * Returns an array of rows to display when the Student Exceptions tabpanel is open
 * and the user is viewing a specific student's student exceptions.
 *
 * In this view, there is one row per chapter. The row displayed is:
 * 1. An editable student exception for this chapter, if it exists,
 *    and the course-level due date for this chapter displayed in plaintext beneath it
 *
 * 2. An editable course-level due date, if there is no student exception for the chapter.
 *
 *    When a chapter's course-level due date is edited in this view, the result is that a new student exception
 *    is created for that chapter, which is then displayed as in (1).
 *
 * 3. Blank, if neither a course due date nor a student exception exists for this chapter.
 */
export const selectStudentExceptionsRows = createSelector(
	[selectCourse, (_state, _courseId, studentId: number) => studentId, selectHydrateData],
	(course, studentId, hydrateData): StudentExceptionsTableRow[] => {
		const student = hydrateData?.students[studentId];
		if (course == null || hydrateData == null || student == null) {
			return [];
		}
		const { chapters } = hydrateData;
		const courseTimeZone = hydrateData.course.timeZone;
		const rows = Object.entries(course.draft.dueDates)
			.flatMap(([chapterFamilyId, chapterDueDates]) => {
				const chapter = chapters[chapterFamilyId];
				const courseDueDate = chapterDueDates.find((dueDate) => dueDate.studentId == null);
				const studentExceptionDueDate = chapterDueDates.find(
					(dueDate) => dueDate.studentId === studentId
				);
				return {
					chapter,
					courseRow:
						courseDueDate != null
							? dueDateToDueDateTableRow({
									dueDate: courseDueDate,
									chapter: chapters[courseDueDate.familyId],
									courseTimeZone,
									student: null
							  })
							: null,
					studentExceptionRow:
						studentExceptionDueDate != null
							? dueDateToDueDateTableRow({
									dueDate: studentExceptionDueDate,
									chapter: chapters[studentExceptionDueDate.familyId],
									courseTimeZone,
									student
							  })
							: null
				};
			})
			.filter((dueDate) => {
				const { chapter } = dueDate;
				if (!chapter) {
					logWarn(
						'Found a due date with a missing chapter while selecting StudentException rows',
						dueDate
					);
					return false;
				}

				return true;
			})
			.sort((a, b) => a.chapter.number - b.chapter.number);

		return rows;
	}
);
