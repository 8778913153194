import { useMemo } from 'react';

import { DateTime } from 'luxon';

import { useGetHydrateQuery } from '../../api';

const defaultMinDate = '2000-01-01';
const defaultMaxDate = '2099-12-31';

export default function useMinMaxDate(courseId: number) {
	const { data: hydrateData } = useGetHydrateQuery(courseId);

	const { minDate, maxDate } = useMemo(() => {
		let minDate = undefined;
		let maxDate = undefined;
		const firstDayOfClass = hydrateData?.academicTerm?.dates?.firstDayOfClass;
		const lastDayOfClass = hydrateData?.academicTerm?.dates?.lastDayOfClass;
		const courseTimeZone = hydrateData?.course.timeZone;
		if (firstDayOfClass != null) {
			// use the first day of class for the earliest valid due date
			minDate = DateTime.fromISO(firstDayOfClass, { zone: courseTimeZone }).toISODate();
		} else {
			minDate = defaultMinDate;
		}
		if (lastDayOfClass != null) {
			// add 11 months to pad for extensions; see T-47244
			maxDate = DateTime.fromISO(lastDayOfClass, { zone: courseTimeZone })
				.plus({ months: 11 })
				.toISODate();
		} else {
			maxDate = defaultMaxDate;
		}
		return { minDate, maxDate };
	}, [hydrateData]);

	return { minDate, maxDate } as const;
}
