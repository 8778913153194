import { ComponentStyleConfig } from '@chakra-ui/react';

export const Menu: ComponentStyleConfig = {
	baseStyle: {
		list: {
			py: 0,
			borderRadius: 0,
			borderBottomWidth: 0,
			borderColor: 'border.100'
		},
		item: {
			py: '0.6rem',
			px: '0.8rem',
			borderBottomWidth: 1,
			borderColor: 'border.100'
		}
	}
};
