import { ChangeRequest, ChangeRequestProgress, ChangeRequestStep } from '../../api/types';
import { Step, StepState } from '../Stepper/types';

const stepLabels: Record<ChangeRequestStep, Record<StepState, string>> = {
	rescore: {
		queued: 'Start',
		'in-progress': 'Starting...',
		completed: 'Started'
	},
	regrade: {
		queued: 'Regrade',
		'in-progress': 'Regrading...',
		completed: 'Regraded'
	},
	delivery: {
		queued: 'Delivery to LMS',
		'in-progress': 'Delivering to LMS...',
		completed: 'Delivered to LMS'
	}
};

const getStepState = (stepKey: ChangeRequestStep, request: ChangeRequest): StepState => {
	if (stepKey === 'rescore') {
		if (request.status !== 'queued') {
			return 'completed';
		} else if (request.status === 'queued') {
			return 'in-progress';
		}
	} else if (stepKey === 'regrade') {
		if (
			request.status === 'updating-calendar' ||
			request.status === 'rescoring' ||
			request.status === 'monitoring-regrade'
		) {
			return 'in-progress';
		} else if (request.status === 'queued') {
			return 'queued';
		} else {
			return 'completed';
		}
	} else if (stepKey === 'delivery') {
		if (request.status === 'monitoring-delivery') {
			return 'in-progress';
		} else if (request.status === 'completed') {
			return 'completed';
		} else {
			return 'queued';
		}
	}

	return 'queued';
};

export const getStepperState = (request: ChangeRequest) => {
	const getStepLabel = (stepKey: keyof ChangeRequestProgress) =>
		stepLabels[stepKey][getStepState(stepKey, request)];

	return request.steps.map<Step>((stepKey: keyof ChangeRequestProgress) => {
		const step = request.progress[stepKey];

		let percentComplete = 0;
		let delayed: boolean | undefined = false;

		if (step) {
			/**
			 * First progress bar should contain progress from the rescore & regrade steps
			 */
			if (stepKey === 'regrade' && request.progress['rescore']) {
				percentComplete =
					(step.percent_complete + request.progress['rescore'].percent_complete) / 2;
			} else {
				percentComplete = step.percent_complete;
			}

			/**
			 * @todo
			 *
			 * Hotfix for delayed until backend stuff is deployed.
			 */
			delayed = step.delayed;
		}

		return {
			label: getStepLabel(stepKey),
			state: getStepState(stepKey, request),
			percentComplete,
			delayed
		};
	});
};
