import { ComponentStyleConfig } from '@chakra-ui/react';

export const List: ComponentStyleConfig = {
	baseStyle: {
		container: {
			item: {
				border: '1px solid black',
				bg: 'red !important'
			}
		}
	},
	defaultProps: {
		colorScheme: 'brandBlue'
	}
};
