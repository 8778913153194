import { DateTime } from 'luxon';

import { ServerDueDate } from '../api/types';
import { DueDateChange, ISO8601DateTime } from '../types';

/**
 * Formats a date to `MM/dd/yyyy`
 *
 * @param iso The timestamp of the date as an ISO string
 * @returns The formatted date string
 */
export const formatDate = (iso: string, timeZone?: string): string => {
	if (!iso) return 'PROBLEM';
	return DateTime.fromISO(iso, { zone: timeZone }).toFormat('MM/dd/yyyy');
};

/**
 * Formats a date to `M/dd/yyyy at HH:MM AM/PM
 */
export const formatDateWithTimeAndTimeZone = (iso: ISO8601DateTime, timeZone?: string): string => {
	return DateTime.fromISO(iso, {
		zone: timeZone
	}).toFormat("M/dd/yyyy 'at' t ZZZZ");
};

/**
 * Splits an ISO 8601 datetime string into a dueDate in ISO date format (YYYY-MM-DD)
 * and a dueTime in 24-hour format (HH:MM).
 *
 * @param iso an ISO 8601 datetime string
 * @returns `{dueDate, dueTime}`
 */
export function isoToDueDateDueTime(iso: string, timeZone: string) {
	const dt = DateTime.fromISO(iso, { zone: timeZone });

	return {
		dueDate: dt.toISODate(),
		dueTime: dt.toLocaleString(DateTime.TIME_24_SIMPLE)
	};
}

/**
 * Returns the time portion of an ISO 8601 datetime string in 24-hour format (HH:MM).
 *
 * @param iso an ISO 8601 datetime string, e.g. 2020-01-01T00:00:00Z
 * @param timeZone the IANA timezone to use, e.g. 'America/New_York'
 * @returns the time in 24-hour format (HH:MM)
 */
export function get24HourTimeFromISO(iso: string, timeZone: string): string {
	return DateTime.fromISO(iso, { zone: timeZone }).toLocaleString(DateTime.TIME_24_SIMPLE);
}

/**
 * Returns the time portion of an ISO 8601 datetime string in human-readable short 12 hour format (e.g. 1:25 PM).
 *
 * @param iso an ISO 8601 datetime string, e.g. 2020-01-01T00:00:00Z
 * @param timeZone the IANA timezone to use, e.g. 'America/New_York'
 * @returns the time in human-readable 12 hour format (e.g. 1:25 PM)
 */
export function getFormattedTimeFromISO(iso: string, timeZone: string): string {
	return DateTime.fromISO(iso, { zone: timeZone }).toFormat('h:mm a');
}

/**
 * Returns the time portion of an ISO 8601 datetime string in human-readable short 12 hour format along with the short time zone string (e.g. 1:25 PM EST).
 * @param iso an ISO 8601 datetime string, e.g. 2020-01-01T00:00:00Z
 * @param timeZone the IANA timezone to use, e.g. 'America/New_York'
 * @returns the time in human-readable 12 hour format with timezone (e.g. 1:25 PM EST)
 */
export function getFormattedTimeFromISOWithTimeZone(iso: string, timeZone: string): string {
	return DateTime.fromISO(iso, { zone: timeZone }).toFormat('h:mm a ZZZZ');
}

/**
 * Convert a dueDate and dueTime to an ISO 8601 string.
 *
 * @param dueDate The date in ISO date format (YYYY-MM-DD)
 * @param dueTime The time in DateTime.TIME_24_SIMPLE format (HH:MM)
 * @param timeZone The IANA timezone to translate the date to, e.g. America/New_York
 * @returns The timestamp of the combined date as an ISO 8601 string
 */
export function dueDateDueTimeToISO(dueDate: string, dueTime: string, timeZone: string): string {
	return DateTime.fromISO(`${dueDate}T${dueTime}`, { zone: timeZone }).toISO();
}

/**
 * Translate a client side `DueDate` to a `ServerDueDate`
 */
export function dueDateToServerDueDate(dueDate: DueDateChange): ServerDueDate {
	return {
		chapter_family_id: dueDate.familyId,
		due_date: dueDate.due,
		penalty_factor: dueDate.penaltyFactor ? dueDate.penaltyFactor / 100 : null,
		penalty_period_length: dueDate.penaltyPeriodLength ? dueDate.penaltyPeriodLength * 86400 : null,
		...(dueDate.studentId && { user_id: dueDate.studentId }),
		...(dueDate._destroy && { _destroy: dueDate._destroy })
	};
}

export type OperatingSystemName = 'Windows' | 'Mac' | 'Other';

export function getOS(): OperatingSystemName {
	if (window.navigator.userAgent.indexOf('Windows')) {
		return 'Windows';
	} else if (window.navigator.userAgent.indexOf('Mac')) {
		return 'Mac';
	}
	return 'Other';
}
