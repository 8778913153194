import { defineStyleConfig } from '@chakra-ui/react';

export const Table = defineStyleConfig({
	baseStyle: {
		th: {
			textTransform: 'none',
			letterSpacing: 'normal',
			fontFamily: 'body'
		},
		thead: {
			th: {
				color: 'black',
				verticalAlign: 'top',
				py: 1,
				px: 2
			}
		},
		tbody: {
			tr: {
				height: 53,
				borderColor: 'border.100',
				'&.even': {
					backgroundColor: 'gray.400'
				},
				_first: {
					borderTopWidth: 2
				},
				_last: {
					borderBottomWidth: 1.5
				},
				'th, td': {
					borderBottom: 0,
					textAlign: 'end'
				},
				td: {
					px: 1
				},
				'&[data-readonly="true"] td': {
					px: 2
				},
				'th[scope="row"]': {
					textAlign: 'start',
					p: 4,
					color: 'black',
					fontSize: 'initial',
					maxWidth: 240,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					'&[data-student="true"]': {
						py: 2,
						fontWeight: 'initial'
					}
				}
			}
		}
	},
	sizes: {
		md: {
			th: {
				fontSize: '14px'
			},
			td: {
				px: 0,
				py: 0
			}
		}
	}
});
