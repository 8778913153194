import React from 'react';

import { Text } from '@chakra-ui/react';

import { useUrlParams } from '../hooks';
import { removeStudentExceptions } from '../store/coursesSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectEarlyStudentExceptions } from '../store/uiSlice';
import { Button } from './Button';
import { Modal } from './Modal';

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

const DeleteEarlyStudentExceptionsModal: React.FC<Props> = React.memo((props) => {
	const { isOpen, onClose } = props;
	const dispatch = useAppDispatch();
	const { courseId } = useUrlParams();
	const earlyStudentExceptions = useAppSelector(selectEarlyStudentExceptions);

	const onDeleteExceptions = () => {
		earlyStudentExceptions?.forEach((dd) => {
			dispatch(
				removeStudentExceptions({
					courseId,
					chapterFamilyId: dd.familyId,
					studentId: dd.studentId
				})
			);
			onClose();
		});
	};

	return (
		<Modal
			size="xl"
			title="Delete early student exceptions?"
			isOpen={isOpen}
			onClose={onClose}
			footer={
				<>
					<Button variant="underline-blue" mr="6" size="lg" onClick={onClose}>
						Continue as is
					</Button>
					<Button colorScheme="blue" size="md" onClick={onDeleteExceptions}>
						Yes, delete exceptions
					</Button>
				</>
			}>
			<Text mb="4">
				With this change, some of your student exception due dates will happen sooner than the rest
				of your course.
			</Text>
			<Text mb="1">
				Would you like to delete those student exceptions so those students can have the same, later
				due dates as the rest of your students?
			</Text>
		</Modal>
	);
});

DeleteEarlyStudentExceptionsModal.displayName = 'DeleteEarlyStudentExceptionsModal';

export default DeleteEarlyStudentExceptionsModal;
