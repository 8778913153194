import React, { useEffect, useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { Heading, HStack, Spacer } from '@chakra-ui/react';

import { selectCourseProperties } from '../../api/selectors';
import { useAppSelector } from '../../store/hooks';

interface Props {
	right?: React.ReactNode;
}

const NavBar: React.FC<Props> = (props) => {
	const { right } = props;
	/**
	 * Do not use `useUrlParams` or `useParams` here as it won't work
	 * (since NavBar isn't a child of the Course route component)
	 */
	const match = useMatch('/courses/:courseId');
	const courseProperties = useAppSelector((state) =>
		selectCourseProperties(state, Number(match?.params.courseId))
	);
	const title = useMemo(
		() =>
			`Manage Due Dates${
				courseProperties ? `: ${courseProperties.number} ${courseProperties.name}` : ''
			}`,
		[courseProperties]
	);

	useEffect(() => {
		if (courseProperties?.number) {
			document.title = `Manage Due Dates: ${courseProperties.number} | Soomo`;
		}
	}, [courseProperties]);

	return (
		<HStack
			background="background.500"
			py="2"
			px="5"
			borderColor="border.100"
			borderBottomWidth="1px"
			align="center">
			<Heading as="h1" size="md">
				{title}
			</Heading>
			<Spacer />
			{right}
		</HStack>
	);
};

export default NavBar;
