import React from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

import { ChakraProps, Stack, Text, Spacer, IconButton, ListItem, VStack } from '@chakra-ui/react';

import { useUrlParams } from '../../hooks';
import {
	removeStudentExceptions,
	selectStudentExceptionsByStudent
} from '../../store/coursesSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { removeTemporaryStudentException, setOpenStudentException } from '../../store/uiSlice';
import { Button } from '../Button';
import { StudentExceptionsTable } from '../DueDatesTable';

interface Props {
	studentId: number;
	open?: boolean;
	temporary?: boolean;
}

/**
 * @todo
 *
 * Maybe try out the native disclosure element
 *
 * `details` & `summary`
 */
const StudentExceptionListItem: React.FC<React.PropsWithChildren<Props>> = (props) => {
	const { studentId, children, open, temporary } = props;

	const dispatch = useAppDispatch();
	const { courseId } = useUrlParams();
	const studentExceptionsByStudent = useAppSelector((state) =>
		selectStudentExceptionsByStudent(state, courseId)
	);
	const studentExceptionsForStudent = studentExceptionsByStudent[studentId];

	const removeException = () => {
		if (!temporary) {
			dispatch(removeStudentExceptions({ studentId, courseId }));
		}

		/**
		 * It is possible that a temporary exception can exist along side a non-temporary exception.
		 * This can occur when a temporary exception is added and edited in one go. This will make
		 * sure things get cleaned up properly.
		 */
		dispatch(removeTemporaryStudentException(studentId));
	};

	return (
		<ListItem sx={styles}>
			<Stack spacing="6" direction="row" align="center">
				<Stack spacing="6" direction={['column', 'row']} alignItems="center">
					<Text as="span" fontWeight="bold" fontSize="lg">
						{children}
					</Text>

					<Text as="span" fontSize="sm" mt="1px !important" whiteSpace="nowrap">
						{studentExceptionsForStudent?.length || 0} exception
						{studentExceptionsForStudent?.length !== 1 && 's'}
					</Text>
				</Stack>

				<Spacer />

				<IconButton
					variant="ghost"
					fontSize="1.5em"
					aria-label="toggle student exception interface"
					onClick={() => {
						dispatch(setOpenStudentException(open ? 0 : studentId));
					}}
					icon={open ? <BsChevronUp /> : <BsChevronDown />}
				/>
			</Stack>
			{open && (
				<VStack align="flex-end" pb="2" mt="2">
					<StudentExceptionsTable studentId={studentId} />
					<Button size="sm" variant="underline-blue" onClick={removeException}>
						Remove all
					</Button>
				</VStack>
			)}
		</ListItem>
	);
};

const styles: ChakraProps['sx'] = {
	listStyle: 'none',
	py: 2,
	borderColor: 'gray.300',
	borderWidth: 1,
	borderX: 'none',
	_notFirst: {
		borderTopWidth: 0
	}
};

export default StudentExceptionListItem;
