import { useEffect, useRef } from 'react';

import { useAppSelector } from '../store/hooks';
import { selectUserInfo } from '../store/uiSlice';

interface BootIntercomConfig {
	firstName: string;
	lastName: string;
	email: string;
	userId: string;
	userHash: string;
}

/**
 * Wrap Intercom booting
 */
function bootIntercom(config: BootIntercomConfig) {
	window.Intercom('boot', {
		app_id: import.meta.env.VITE_INTERCOM_APP_ID,
		name: `${config.firstName} ${config.lastName}`,
		email: config.email,
		user_id: `${config.userId}`,
		user_hash: config.userHash
	});
}

/**
 * This hook will boot Intercom with values from the store
 */
export function useBootIntercom() {
	const hasBooted = useRef(false);
	const userInfo = useAppSelector(selectUserInfo);
	const { intercomHash, ...restOfUserInfo } = userInfo;

	useEffect(() => {
		if (userInfo.userId && intercomHash && !hasBooted.current) {
			bootIntercom({
				userHash: intercomHash,
				...restOfUserInfo
			});
			hasBooted.current = true;
		}
	}, [intercomHash, restOfUserInfo, userInfo]);
}
