import React, { useState } from 'react';

import { selectCourseTimeZone } from '../../api/selectors';
import { useUrlParams } from '../../hooks';
import { copyStudentException } from '../../store/coursesSlice';
import { rowToDueDate } from '../../store/helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
	selectDuplicatingStudentException,
	setDuplicatingStudentException
} from '../../store/uiSlice';
import { Button } from '../Button';
import StudentSelect from '../Input/StudentSelect';
import { Modal } from '../Modal';

interface Props {
	isOpen: boolean;
}

const DuplicateExceptionModal: React.FC<Props> = (props) => {
	const { isOpen } = props;
	const dispatch = useAppDispatch();
	const { courseId } = useUrlParams();
	const courseTimeZone = useAppSelector((state) => selectCourseTimeZone(state, courseId));
	const studentExceptionRow = useAppSelector(selectDuplicatingStudentException);
	const [newStudentId, setNewStudentId] = useState(0);

	if (!studentExceptionRow) return null;

	const onClose = () => {
		/**
		 * Reset `newStudentId` and `duplicatingStudentException` when we close the modal.
		 */
		setNewStudentId(0);
		dispatch(setDuplicatingStudentException());
	};

	const handleDuplicate = () => {
		dispatch(
			copyStudentException({
				courseId,
				studentId: newStudentId,
				source: rowToDueDate(studentExceptionRow, courseTimeZone)
			})
		);
		onClose();
	};

	return (
		<Modal
			size="lg"
			title="Duplicate student exception to"
			isOpen={isOpen}
			onClose={onClose}
			footer={
				<>
					<Button variant="underline-blue" mr="6" size="lg" onClick={onClose}>
						Cancel
					</Button>
					<Button colorScheme="blue" size="md" onClick={handleDuplicate} disabled={!newStudentId}>
						Duplicate
					</Button>
				</>
			}>
			<StudentSelect
				value={newStudentId}
				chapterFamilyId={studentExceptionRow.chapter.familyId}
				onChange={setNewStudentId}
				showDefault
			/>
		</Modal>
	);
};

export default DuplicateExceptionModal;
