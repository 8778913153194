import React from 'react';

import {
	Heading,
	Spacer,
	FormControl,
	FormLabel,
	Switch,
	Flex,
	useDisclosure,
	Stack
} from '@chakra-ui/react';

import { AllDueDatesTable, DuplicateExceptionModal } from '../components/DueDatesTable';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
	selectDuplicatingStudentException,
	selectShowStudentExceptions,
	setShowStudentExceptions
} from '../store/uiSlice';

const AllDueDates: React.FC = () => {
	const dispatch = useAppDispatch();
	const showStudentExceptions = useAppSelector(selectShowStudentExceptions);
	const duplicatingStudentException = useAppSelector(selectDuplicatingStudentException);
	const { isOpen: isDuplicateModalOpen } = useDisclosure({
		isOpen: Boolean(duplicatingStudentException)
	});

	return (
		<Flex my={[3, 6]} alignItems="flex-start" direction="column">
			<Stack
				direction={{ base: 'column', md: 'row' }}
				w="100%"
				justify={'space-between'}
				alignItems={{ base: 'flex-start', md: 'center' }}
				mb="10">
				<Heading as="h2">All your course due dates</Heading>
				<Spacer />
				<FormControl display="flex" w="auto" alignItems="center" flexDirection="row-reverse">
					<FormLabel mb="0" mr="0" aria-hidden="true">
						Show student exceptions
					</FormLabel>
					<Switch
						mr="2"
						aria-label="Show student exceptions"
						isChecked={showStudentExceptions}
						onChange={() => dispatch(setShowStudentExceptions(!showStudentExceptions))}
					/>
				</FormControl>
			</Stack>

			<AllDueDatesTable />

			<DuplicateExceptionModal isOpen={isDuplicateModalOpen} />
		</Flex>
	);
};

export default AllDueDates;
